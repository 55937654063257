<app-modal id="new-features" size="new-features" [dismissable]="false">
  <div class="header">
    <div class="title">
      <h1>Updates and new features</h1>
      <mat-icon class="close-icon" (click)="closeModal()">close</mat-icon>
    </div>
    <p class="date">{{ date }}</p>
  </div>
  
  <!-- Replace image with new design & update text for each release -->
  <div class="body">
    <img
      class="features-logo"
      src="../assets/images/new-features-logo.png"
      alt="New feature logo"
    />
    <h1 class="mt-24">New feature</h1>
    <iframe width="500" height="315" src="https://app.vaam.io/embed/ofwCWAK" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
    <br/> &nbsp; <br/>
    <h4>Extend deadline on order</h4>
    <p>
      The day is finally here - now Returbo let’s you extend a return/exchange/complaint
      deadline on any given order! You can find this feature in the Returbo App. Go to 
      <b>Settings</b> → <b>Request Settings</b> → <b>General</b> and scroll to 
      <b>Extending deadline on a specific order</b>.
      <br/> &nbsp; <br/>
      Learn more about this new feature with a video from Julia: 
      <a href="https://app.vaam.io/share/ofwCWAK" target="_blank">How does Extended Deadline work?💫</a>
      &nbsp; <br/>
      Prefer to read? Here is a written guide:
      <a href="https://docs.google.com/document/d/1zrMCdtfjxv1ijkURd1NeZ7x-rDnHiZeG7mQQQ8IqyB4/edit?usp=sharing" target="_blank">How does Extended Deadlines work?</a>
    </p>
    <h4>Tracking link in emails</h4>
    <p>
      We have now added a key so you can add a tracking link in the emails to your customers.
      This way they can track the package as well. To add this feature to your emails, go to 
      <b>Settings → Customization</b> and scroll down to <b>Customer notification emails</b>.
      You can find the keyword in {{ '{}' }} Useful keywords, <i>{{ '{' }}tracking_link{{ '}' }}</i>. Copy and paste 
      this keyword in all the emails you want your customer to have a tracking link such as all
      the Shipping Slip Sent emails.
    </p>
    <h4>Small reminder - Special deadlines</h4>
    <p>
      A little reminder that Returbo has a feature for Essentials and Pro customers that you can
      add a Special deadline during the holidays. Special deadlines are deadlines that you want
      to extend or shorten for a certain period, for example increasing Christmas return deadlines
      from 14 days to 90 days over the Christmas period. 
      <br/> &nbsp; <br/>
      Here is a guide on how to create a special deadline:
      <a href="https://docs.google.com/document/d/1uKYcaQ917gMKaP3vRLmMSlH3WSrTDuj8w_sZCwrNYLY/edit?tab=t.0#heading=h.na30rq586k9c" target="_blank">Special deadlines guide</a>
      &nbsp; <br/>
      And here is a video on how to do it as well: 
      <a href="https://app.vaam.io/share/JcnJ03F" target="_blank">Special deadlines video</a>
    </p>
  </div>
</app-modal>