<app-settings-section
  [hasContent]="!isLoadingSettings && !isLoadingPricePlans"
  [isLoading]="isLoadingSettings || isLoadingPricePlans || isSaving"
  [title]="title"
  [description]="description"
  [onBoardingStepCompleted]="onboardingCompleted"
  [hasNewFeatures]="false"
  [feature]="feature"
  id="select-plan"
>
<app-select-plan 
(saving)="setIsSaving($event)" 
(loadingSettings)="setIsLoadingSettings($event)"
(loadingPricePlans)="setIsLoadingPricePlans($event)"></app-select-plan>
</app-settings-section>