<app-settings-section
  id="complaints-image-instructions"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
<div>
    <app-checkbox
      size="medium"
      (checkedChange)="toggleInstructions($event)"
      [checked]="complaintSettings.has_image_instructions"
      [disabled]="isSaving || !isEnabled()"
      ><span class="text">
        Include instructions for the image that will appear to the customer
      </span>
    </app-checkbox>

    <h4 class="title mb-5">Instructions</h4>
    <input
      (focusout)="saveSettings()"
      class="text-input"
      type="text"
      [disabled]="
        !complaintSettings.has_image_instructions ||
        isSaving ||
        !complaintSettings.is_enabled
      "
      placeholder="e.g. We need the photo of the sides and the front."
      [(ngModel)]="complaintSettings.image_instructions_field"
    />
    <p class="information">
      Please keep it to 60 characters. Visible to the customer.
    </p>
  </div>
</app-settings-section>