import { Component } from '@angular/core';

@Component({
  selector: 'app-pay-as-you-go-modal',
  templateUrl: './pay-as-you-go-modal.component.html',
  styleUrls: ['./pay-as-you-go-modal.component.scss'],
})
export class PayAsYouGoModalComponent {
  date = 'October 2024';
}
