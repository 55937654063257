import { Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-select-plan-settings',
  templateUrl: './select-plan-settings.component.html',
  styleUrls: ['./select-plan-settings.component.scss'],
})
export class SelectPlanSettingsComponent implements OnInit {
  title = 'Select a plan';
  description =
    'We currently offer 3 pricing plans, all of which are billed monthly. When you have selected a plan, you will be redirected to Shopify to confirm your subscription.';
  
  feature = 'New feature';
  isSaving = false;
  isLoadingSettings = true;
  isLoadingPricePlans = true;
  onboardingCompleted = false;
  currentPricePlanId = '';

  constructor(
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe({
      next: (res) => {
        this.currentPricePlanId = res.id;
        this.checkOnBoarding();
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load settings.');
      }
    });
    
    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll(`#${event.anchor}`);
        }, 200);
      }
    });
  }

  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  
  checkOnBoarding(): void {
    this.currentPricePlanId
      ? (this.onboardingCompleted = true)
      : (this.onboardingCompleted = false);
  }
  
  setIsSaving(isSaving: boolean): void {
    this.isSaving = isSaving
  }

  setIsLoadingSettings(isLoadingSettings: boolean) {
    this.isLoadingSettings = isLoadingSettings;
  }

  setIsLoadingPricePlans(isLoadingPricePlans: boolean) {
    this.isLoadingPricePlans = isLoadingPricePlans;
  }
}
