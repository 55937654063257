<app-settings-section
  id="complaints-toggle"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [bannerStats]="bannerStats"
  [isToggleable]="false"
  [hasNewFeatures]="false"
>
    <div class="d-flex">
        <app-slider
          [disabled]="isLoading || isSaving"
          [hideText]="true"
          (toggleChange)="toggleComplaints()"
          [checked]="complaintSettings.is_enabled"
        ></app-slider>
        <span class="ml-16">
            Complaints is turned <b>{{ complaintSettings.is_enabled ? "ON" : "OFF" }}</b>
        </span>
    </div>
</app-settings-section>