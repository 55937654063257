import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { DeadlineEntry } from 'src/app/settings/models/deadline-entry.model';

@Component({
  selector: 'app-deadline-list',
  templateUrl: './deadline-list.component.html',
  styleUrls: ['./deadline-list.component.scss'],
})
export class DeadlineListComponent {
  @Input()
  isLoading = false;
  @Input()
  isSaving = false;
  @Input()
  title = 'Deadlines'
  @Input()
  displayAddAction = true;
  @Input()
  addActionTitle = 'Add a deadline'
  @Input()
  disableAddAction = false;
  @Input()
  canView = false;
  @Input()
  canEdit = false;
  @Input()
  deadlines: DeadlineEntry[] = [];
  @Input()
  planRestrictions: string[] = [];

  @Output()
  add: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  view: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  edit: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  delete: EventEmitter<string> = new EventEmitter<string>();

  constructor(private modalService: ModalService){}

  getVisibleEntries(): DeadlineEntry[] {
    return this.deadlines.slice(0, 2);
  }

  getModalTitle(): string{
    return `${this.title.replace(' ', '-').toLowerCase()}-${this.deadlines[0]?.id ?? 0}`;
  }

  getStatus(status: string): string {
    return status.replace(' ', '-').toLowerCase();
  }

  emit(type: string, value: string): void {
    if (type === 'add') {
      this.add.emit(true);
    }
    else if (type === 'view') {
      this.view.emit(value);
    }
    else if (type === 'edit') {
      this.edit.emit(value);
    }
    else if (type === 'delete') {
      this.delete.emit(value);
    }
  }

  openModal(): void {
    this.modalService.open(this.getModalTitle());
  }

  closeModal(): void {
    this.modalService.dismiss();
  }
}