<div>
    <div
        *ngIf="getVisibleEntries().length > 0 || !displayAddAction || isSaving"
        class="title-box d-flex justify-space-between"
    >
        <h3 class="title mt-0">
            {{ title }}
            <app-loader 
                *ngIf="isSaving" 
                class="loader"
                size="medium"
            ></app-loader>
        </h3>
        <p 
            *ngIf="deadlines.length > 2"
            class="action expand mt-0"
            (click)="openModal()"
        >
            Show full list
        </p>
    </div>

    <div class="deadline-list" [ngClass]="{ blur: isSaving }">
        <app-deadline-entry
            *ngFor="let deadline of getVisibleEntries()"
            [deadline]="deadline"
            [canEdit]="canEdit"
            [canView]="canView"
            (view)="emit('view', deadline.id ?? '')"
            (edit)="emit('edit', deadline.id ?? '')"
            (delete)="emit('delete', deadline.id ?? '')"
        ></app-deadline-entry>
        <p 
            *ngIf="deadlines.length > 0"
            class="list-overflow"
        >
            Showing {{ getVisibleEntries().length }} out of {{ deadlines.length }} {{ title.toLowerCase() }}
        </p>
    </div>
    <p 
        [ngClass]="{disabled: disableAddAction}"
        *ngIf="displayAddAction"
        class="action add"
        (click)="emit('add', '')"
    >
        {{ addActionTitle }}
        <ng-container *ngIf="planRestrictions.length > 0">
            <span *ngFor="let plan of planRestrictions" class="pro-badge ml-8">{{ plan }}</span>
        </ng-container>
    </p>
</div>

<app-modal
    [id]="getModalTitle()"
    [dismissable]="false"
    size="large"
>
    <div class="modal-content">
        <h2>{{ title }}</h2>
        <p>
            Here is a full list of all your {{ title.toLowerCase() }}. You can have a closer look by clicking on the individual deadline.
        </p>
        <div class="modal-deadline-list">
            <app-deadline-entry
                *ngFor="let deadline of deadlines"
                [deadline]="deadline"
                [canEdit]="canEdit"
                [canView]="canView"
                (view)="emit('view', deadline.id ?? '')"
                (edit)="emit('edit', deadline.id ?? '')"
                (delete)="emit('delete', deadline.id ?? '')"
            ></app-deadline-entry>
        </div>
        <div class="modal-footer">
            <p 
                *ngIf="deadlines.length > 0"
                class="list-overflow"
            >
                Showing {{ deadlines.length }} out of {{ deadlines.length }} {{ title.toLowerCase() }}
            </p>
            <app-new-button
                (byClick)="closeModal()"
            >
                Close
            </app-new-button>
        </div>
    </div>
</app-modal>