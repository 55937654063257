import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReturnRequest, ReturnsResponse } from '../models/return-request.model';
import { UpdateReturnRequest } from '../models/update-return-request.model';
import { ListViewRequestUpdate } from '../models/list-view-request-update.model';

@Injectable({
  providedIn: 'root',
})
export class ReturnsService {
  private returnsData: BehaviorSubject<any> = new BehaviorSubject([]);
  private filterValue = new BehaviorSubject('');
  private selectedReturn: BehaviorSubject<ReturnRequest> = new BehaviorSubject(
    new ReturnRequest()
  );
  private isShowingModal = new BehaviorSubject(false);
  private shouldTimelineUpdate = new BehaviorSubject(false);
  private isLoadingReturn = new BehaviorSubject(false);
  private isLoading = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {}

  setLoading(value: boolean): void {
    this.isLoading.next(value);
  }

  getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  setReturnsDataSubject(data: ReturnsResponse): void {
    this.returnsData.next(data);
  }

  getReturnsDataSubject(): Observable<ReturnsResponse> {
    return this.returnsData.asObservable();
  }

  setFilter(value: string): void {
    this.filterValue.next(value);
  }

  getFilterValue(): Observable<string> {
    return this.filterValue.asObservable();
  }

  setSelectedReturnSubject(
    value: ReturnRequest,
    updateTimeline: boolean
  ): void {
    this.shouldTimelineUpdate.next(updateTimeline);
    this.selectedReturn.next(value);
  }

  getSelectedReturnSubject(): Observable<ReturnRequest> {
    return this.selectedReturn.asObservable();
  }

  setisShowing(value: boolean): void {
    this.isShowingModal.next(value);
  }

  getIsShowing(): Observable<boolean> {
    return this.isShowingModal.asObservable();
  }

  getReturnsList(
    statuses?: string[],
    skip?: number,
    query?: string
  ): Observable<ReturnsResponse> {
    let requestString = '/api/Return/list?size=25';
    if (statuses) {
      requestString = requestString.concat(
        `${'&statuses=' + statuses.join(',')}`
      );
    }
    if (skip) {
      requestString = requestString.concat(`${'&skip=' + skip}`);
    }
    if (query) {
      requestString = requestString.concat(`${'&query=' + query}`);
    }
    return this.httpClient.get<ReturnsResponse>(
      `${environment.apiUrl + requestString}`
    );
  }

  updateReturn(body: UpdateReturnRequest): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/api/Return`,
      body
    );
  }

  getReturnById(id: string): Observable<ReturnRequest> {
    this.isLoadingReturn.next(true);
    return this.httpClient.get<ReturnRequest>(
      `${environment.apiUrl}/api/Return/${id}`
    );
  }

  resendEmail(returnId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/api/Return/${returnId}/resend-email`,
      null
    );
  }

  getShouldTimelineUpdate(): Observable<boolean> {
    return this.shouldTimelineUpdate.asObservable();
  }

  getUserInfo(startDate: string, endDate: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${environment.apiUrl}/api/return/export?startDate=${startDate}&endDate=${endDate}`
    );
  }

  switchStatusOnRequest(updateInfo: ListViewRequestUpdate): void {
    const requestData: ReturnsResponse = this.returnsData.getValue();

    //Remove from view since the status is different from the selected status
    if (updateInfo.removeFromView && requestData.returns) {
      const index = requestData.returns.findIndex(
        (request) => request.id === updateInfo.requestId
      );

      if (index > -1) {
        requestData.returns.splice(index, 1);
        requestData.total_count--;
        this.returnsData.next(requestData);
      }
      return;
    }

    //Update request with new status since the request should still be visible
    const requestToUpdate = requestData.returns?.find(
      (request) => request.id === updateInfo.requestId
    );
    if (requestToUpdate) {
      requestToUpdate.status = updateInfo.newStatus;
      requestToUpdate.updated_at = new Date().toString();
      this.returnsData.next(requestData);
    }
  }
}
