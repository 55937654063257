import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Item } from 'src/app/models/item.model';
import { MerchantComment } from 'src/app/models/merchant-comment.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { StatusStyling } from 'src/app/models/status-styling.model';
import { TimelineStepper } from 'src/app/models/timeline-stepper.model';
import { Timeline } from 'src/app/models/timeline.model';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { FilterStatusTypeEnum } from 'src/app/shared/enums/filter-status-type.enum';
import { ItemDecisionEnum } from 'src/app/shared/enums/item-decision.enum';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { filterOnStatus } from '../../filter-on-status';
import { ReturnsService } from '../../returns.service';
import { resolvedStatuses, returnStatusesFilters } from '../../table-filters';
import {
  complaintRefund,
  complaintRefundResolved,
  complaintReturnItems,
  complaintReturnItemsStatus,
  hasComplaintReason
} from '../complaint-return-items';
import { refundStatusValuesText } from '../refund-status-values';
import { timelineValues } from './timeline';

@Component({
  selector: 'app-right-content',
  templateUrl: './right-content.component.html',
  styleUrls: ['./right-content.component.scss'],
})
export class RightContentComponent implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;
  return: ReturnRequest = new ReturnRequest();
  step = 2;
  timelinesStatuses: TimelineStepper[] = [];
  ReturnStatusEnum = ReturnStatusEnum;
  returnStatusesFilters = returnStatusesFilters;
  isLoadingTimeline = true;
  resolvedStatuses = resolvedStatuses;
  complaintRefund = complaintRefund;
  complaintReturnItems = complaintReturnItems;
  complaintReturnItemsStatus = complaintReturnItemsStatus;
  complaintRefundResolved = complaintRefundResolved;
  hasComplaints = hasComplaintReason;
  filterOnStatus = filterOnStatus;

  isComplaintRefund = false;
  isComplaintReturnItems = false;
  isComplaintRefundResolved = false;
  complaintSettings: ComplaintSettings = new ComplaintSettings();
  isResolved = false;
  refundStatusText = refundStatusValuesText;
  isExchangeRequest = false;

  comments: MerchantComment[] = [];

  constructor(
    private returnsService: ReturnsService,
    private complaintSettingsService: ComplaintSettingsService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.getComplaintsSettings();

    this.returnsService
      .getSelectedReturnSubject()
      .subscribe((selectedReturn: ReturnRequest) => {
        this.isLoadingTimeline = true;
        this.return = selectedReturn;

        if (selectedReturn.timeline.length > 0) {
          this.isLoadingTimeline = false;
          this.chatService.setLoading(true);
          this.chatService
            .getComments(selectedReturn.id)
            .subscribe((comments: MerchantComment[]) => {
              this.comments = comments;
              this.chatService.setLoading(false);
            });
          this.returnsService.setLoading(false);
        }
        this.isComplaintRefund = this.complaintRefund(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned,
          this.complaintSettings.should_send_instant_refund
        );
        this.isComplaintReturnItems = this.complaintReturnItems(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        );
        this.isComplaintRefundResolved = this.complaintRefundResolved(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        );
        this.isResolved = this.filterOnStatus(
          FilterStatusTypeEnum.resolved,
          true,
          this.return.status
        );
        this.isExchangeRequest = this.return.type === ReturnReasonType.Exchange;
        if (
          selectedReturn.status &&
          selectedReturn.status !== ReturnStatusEnum.Undefined
        ) {
          this.timelinesStatuses = [];
          timelineValues.forEach((timeline: TimelineStepper) => {
            if (
              this.hasComplaints(this.return.items) &&
              (timeline.showOnRefund ||
                timeline.showOnReturn ||
                ['Order created', 'Order fullfilled'].includes(timeline.title))
            ) {
              if (
                (timeline.showOnReturn && this.isComplaintReturnItems) ||
                this.isComplaintRefundResolved === timeline.showOnReturn ||
                ['Order created', 'Order fullfilled'].includes(
                  timeline.title
                ) ||
                (!this.isComplaintRefundResolved &&
                  !this.isComplaintReturnItems &&
                  !this.isComplaintRefund)
              ) {
                if (timeline.title === 'Complaint resolved') {
                  timeline.step = 5;
                }
                this.timelinesStatuses.push(timeline);
              } else if (
                timeline.showOnRefund === this.isComplaintRefund &&
                !this.isComplaintReturnItems &&
                !this.isComplaintRefundResolved
              ) {
                if (timeline.title === 'Complaint resolved') {
                  timeline.step = 4;
                }
                this.timelinesStatuses.push(timeline);
              }
            } else if (
              ((!timeline.showOnRefund && !timeline.showOnReturn) ||
                ['Order created', 'Order fullfilled'].includes(
                  timeline.title
                )) &&
              !this.hasComplaints(this.return.items)
            ) {
              this.timelinesStatuses.push(timeline);
            }
          });
          this.step =
            timelineValues.find((resTimeline: TimelineStepper) => {
              if (this.hasComplaints(this.return.items)) {
                return resTimeline.statuses.includes(selectedReturn.status);
              } else {
                return (
                  resTimeline.statuses.includes(selectedReturn.status) &&
                  !resTimeline.showOnRefund &&
                  !resTimeline.showOnReturn
                );
              }
            })?.step ?? 0;
        }
        if (this.stepper) {
          this.stepper.selectedIndex = this.step;
        }
      });
  }

  shouldDisplayDeadlineBadge(timeline: TimelineStepper): boolean {
    const deadlineText = this.getDeadlineBadgeText();
    if (deadlineText.length < 1) {
      return false;
    }
    return (timeline.step === 0 && deadlineText.includes('window')) || 
            (timeline.step == 2 && deadlineText.includes('extended'));
  }

  getDeadlineBadgeText(): string {
    const foundDeadline = this.return.timeline.find(
      (timeline) => timeline.description === 'Order Created'
    );
    return foundDeadline?.sub_events[0]?.description ?? '';
  }

  getCompleted(index: number): boolean {
    return index <= this.step ? true : false;
  }

  getTotalItems(): string | number {
    let totalItems = 0;
    this.return.items.forEach(
      (item: Item) => (totalItems = totalItems + Number(item.quantity))
    );
    return totalItems;
  }

  getTotalPrice(): number {
    let totalPrice = 0;
    this.return.items.forEach(
      (item: Item) =>
        (totalPrice =
          totalPrice +
          (Number(item.quantity) * Number(item.price) -
            Number(item.total_discount)))
    );
    return totalPrice;
  }

  getTotalPriceForItem(item: Item): string {
    return (
      Number(item.price) * item.quantity -
      Number(item.total_discount)
    ).toFixed(2);
  }

  addClass(status: string, item: Item): string {
    if (
      this.isExchangeRequest &&
      item.status === ReturnItemStatusEnum.RefundApproved &&
      item.merchant_compensation_choice === CompensationOption.Refund
    ) {
      return 'partially';
    }
    const filteredStatus = this.resolvedStatuses.filter(
      (resolved: StatusStyling) => resolved.status.includes(status)
    );
    return filteredStatus.length > 0 ? filteredStatus[0].class : 'incoming';
  }

  getTimelineDate(status: string): any {
    if (status === 'order_created_at' || status === 'order_fulfilled_at') {
      return this.return[status as keyof ReturnRequest];
    }
    const timeline = this.return.timeline.filter(
      (timelineEvent: Timeline) =>
        timelineEvent.description.toLowerCase() === status
    );
    return timeline.length > 0 ? timeline[0].created_at : '';
  }

  displayStatus(status: string, item: Item): string {
    if (
      this.isExchangeRequest &&
      item.status === ReturnItemStatusEnum.RefundApproved &&
      item.merchant_compensation_choice === CompensationOption.Refund
    ) {
      return 'Partially accepted';
    }
    if (
      this.complaintReturnItemsStatus(
        status,
        this.return.items,
        this.complaintSettings.should_item_be_returned
      )
    ) {
      return status === ReturnItemStatusEnum.ItemDenied ||
        status === ReturnItemStatusEnum.RefundDenied
        ? ItemDecisionEnum.Rejected
        : ItemDecisionEnum.Accepted;
    }
    const filteredStatus = this.resolvedStatuses.find(
      (resolved: StatusStyling) => resolved.status.includes(status)
    );
    return filteredStatus ? filteredStatus.title : 'Incoming';
  }

  allItemsDeclined(): boolean {
    return this.return.items.every(
      (item: Item) =>
        !item.decision || item.status === ReturnItemStatusEnum.RefundDenied
    );
  }

  showItemsTable(timeline: TimelineStepper): boolean {
    return (
      timeline.step === 0 ||
      (timeline.step === 2 &&
        this.return.status !== ReturnStatusEnum.RequestReceived &&
        timeline.showOnRefund !== this.isComplaintRefund) ||
      timeline.step === 5
    );
  }

  getComplaintsSettings(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((complaintOptions) => {
        this.complaintSettings = complaintOptions;
      });
  }

  hasComplaintNewItem(): boolean {
    return (
      this.hasComplaints(this.return.items) &&
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.NewItem
      )
    );
  }

  rejectedItemsCount(): number {
    if (this.isResolved) {
      return this.return.items.filter(
        (item) => item.status === ReturnItemStatusEnum.RefundDenied
      ).length;
    }
    return this.return.items.filter((item) => !item.decision).length;
  }
}
