import { ExtendByOption } from "src/app/shared/enums/extend-by-option.enum";
import { ReturnReasonType } from "src/app/shared/enums/return-reason-type.enum";

export class ExtendedDeadlineRequest {
    original_order_id? = '';
    name = '';
    extend_by: ExtendByOption = ExtendByOption.OneWeek;
    request_types: ReturnReasonType[] = [];
    order_created_at: Date = new Date();
    order_shipped_at: Date = new Date();
}