<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="true"
  feature="New feature"
>
<div class="section">
    <app-deadline-list
        [isLoading] = "isLoading"
        [isSaving] = "isSaving"
        title = "Active orders"
        [displayAddAction] = "true"
        addActionTitle = "Extend deadline on specific order"
        [canView] = "true"
        (view)="openViewModal($event)"
        (add)="openCreateModal()"
        (delete)="openDeleteModal($event)"
        [deadlines]="activeDeadlineEntries"
    ></app-deadline-list>

    <ng-container *ngIf="expiredDeadlineEntries.length > 0">
      <div class="mt-24"></div>
  
      <app-deadline-list
        [isLoading] = "isLoading"
        [isSaving] = "isSaving"
        title = "Expired orders"
        [displayAddAction] = "false"
        [canView] = "true"
        (view)="openViewModal($event)"
        (delete)="openDeleteModal($event)"
        [deadlines]="expiredDeadlineEntries"
      ></app-deadline-list>
    </ng-container>
</div>
</app-settings-section>

<app-modal
  size="x-small-rectangle"
  id="extended-deadline"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Extend deadline on an order</h1>
  <p class="deadline-description mt-0">
    Extend the deadline on a specific order, so that the customer can
    still submit a request even after the general deadline has passed.
  </p>

  <app-alert
    [type]="getAlertType()"
    [imgIcon]="getAlertIcon()"
    text="Once saved, the deadline cannot be edited. You need to delete the current deadline and make a new one."
    [showCloseButton]="false"
  ></app-alert>

  <form [formGroup]="deadlineForm">
    <div class="deadline-input-wrap mb-16 mt-16">
      <p class="deadline-label">Search for an order</p>
      <input
        *ngIf="!selectedOrder.order_id"
        class="search"
        [ngClass]="{
          'error': !isSearchOrderValid && deadlineForm.value['order_name'].length > 0,
          'disabled': isSaving || isViewing
        }"
        (keyup)="searchOrder($event)"
        (click)="searchOrder($event)"
        placeholder="Search by order number"
        type="text"
        maxlength="200"
        [matMenuTriggerFor]="menu"
        #clickHoverMenuTrigger="matMenuTrigger"
        [(ngModel)]="orderInfo"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="isSaving || isViewing"
      />
      <div 
        *ngIf="selectedOrder.order_id" 
        class="selected-order-box search"
        (click)="selectOrder(undefined)"
        >
        <p> {{ selectedOrder.order_name }} </p>
        <p class="gray"> • </p>
        <p class="gray"> {{ selectedOrder.customer_name }} </p>
      </div>
      <mat-menu #menu="matMenu" class="mt-10">
        <span
          class="mat-menu-item spacing search"
          *ngFor="let order of searchOrders"
          (click)="selectOrder(order)"
          [innerHTML]="getSearchHtml(order)"
        ></span>
      </mat-menu>
      <span
       class="instructions mt-2"
       [ngClass]="{ hidden: !isSearchOrderValid && deadlineForm.value['order_name'].length > 0 }"
      >
        Search and select a specific order whose deadline you want to extend.
      </span>
      <span
        class="instructions error mt-2"
        [ngClass]="{ hidden: isSearchOrderValid || deadlineForm.value['order_name'].length === 0 }"
      >
        Please search by the order number. If you have a prefix with letters inside
        your order number – skip them and use the numbers only.
      </span>
    </div>
    
    <div class="deadline-input-wrap mb-16 mt-16">
      <p class="deadline-label">Extend for</p>
      <app-animated-dropdown
          placeholder="Select"
          (selectedChange)="setTimeOption($event)"
          [selectedOption]="getTimeOption()"
          [options]="extendByDisplayOptions"
          [disabled]="isSaving || isViewing || !selectedOrder.order_id"
      ></app-animated-dropdown>
      <span class="instructions mt-2">
        Select the extended period within which the customer can submit a request. 
        After the period ends, the customer won’t be able to make a request, 
        unless the “indefinite” option is selected or you manually edit this deadline.
      </span>
    </div>

    <div class="deadline-input-wrap mb-24">
      <p class="deadline-label">Allowed request types</p>
      <div 
        *ngFor="let requestType of requestTypes"
      >
        <mat-checkbox
          *ngIf="isRequestTypeActive(requestType)"
          id="extend-{{requestType.toLowerCase()}}"
          (change)="updateRequestTypes($event, requestType)"
          formControlName="extend_{{requestType.toLowerCase()}}"
          class="checkbox"
          [disabled]="isSaving || isViewing || !selectedOrder.order_id"
          color="accent"
        >
        <span class="request-type-order-info">
            {{ getDisplayName(requestType) }}
            <p>
              {{ getRequestDeadlineText(requestType) }}
            </p>
        </span>
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="!isViewing" class="deadline-actions mt-24">
      <app-new-button buttonStyle="cancel" (click)="close()">
        Cancel
      </app-new-button>
      <app-new-button [disabled]="checkDisabled()" (click)="saveChanges()">
        Save deadline
      </app-new-button>
    </div>
    <div *ngIf="isViewing" class="deadline-actions-view mt-24">
      <span class="tetriary-action" (click)="openDeleteModal(deadlineOnEdit.original_order_id ?? '')">
        Delete deadline
      </span>
      <div class="d-flex">
        <app-new-button 
          *ngIf="canDeactivate()" 
          buttonStyle="cancel" 
          (click)="openDeactivateModal()"
        >Deactivate deadline</app-new-button>
        <app-new-button (click)="close()">
          Back
        </app-new-button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  size="x-small-rectangle"
  id="duplicant-extended-deadline"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">You have already extended this order</h1>
  <p class="deadline-description mt-0 mb-0">
    You have already extended the deadline for {{ selectedOrder.order_name }}.
    Make sure to delete the current extended deadline before you create a new one.
  </p>
  <p>Would you like to open <b>the current extended deadline</b> for <b>{{ selectedOrder.order_name }}</b> now?</p>

  <div class="deadline-actions mt-24">
    <app-new-button
      buttonStyle="cancel"
      (click)="closeModal('duplicant-extended-deadline')"
    >
      Back
    </app-new-button>
    <app-new-button (click)="openExistingDeadline()">
      Open existing extended deadline
    </app-new-button>
  </div>
</app-modal>

<app-modal
  size="x-small-rectangle"
  id="deactivate-extended-deadline"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Deactivating extended deadline</h1>
  <p class="deadline-description mt-0 mb-0">
    By selecting "Deactivate", the customer won’t be able to make a request 
    on this specific order anymore. However, if the request is in progress
    then it will be allowed to be completed. The deadline on this order will 
    be shown in the expired orders list.
  </p>
  <p>Do you still wish to proceed?</p>

  <div class="deadline-actions mt-24">
    <app-new-button
      buttonStyle="cancel"
      (click)="goBack('deactivate-extended-deadline')"
    >
      Go back
    </app-new-button>
    <app-new-button (click)="deactivateDeadline()">
      Proceed and deactivate
    </app-new-button>
  </div>
</app-modal>

<app-modal
  size="x-small-rectangle"
  id="delete-extended-deadline"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Deleting an extended deadline</h1>
  <p class="deadline-description mt-0 mb-0">
    By selecting "Delete", the customer won’t be able to make a request on this specific
    order anymore. However, if the request is in progress then it will be allowed to be
    completed. It won’t be shown in the expired orders list.
  </p>
  <p>Do you still wish to proceed?</p>

  <div class="deadline-actions mt-24">
    <app-new-button
      buttonStyle="cancel"
      (click)="goBack('delete-extended-deadline')"
    >
      Go back
    </app-new-button>
    <app-new-button (click)="deleteDeadline()">
      Proceed and delete
    </app-new-button>
  </div>
</app-modal>

