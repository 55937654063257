import { Component, OnInit } from '@angular/core';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-complaint-options',
  templateUrl: './complaint-options.component.html',
  styleUrls: ['./complaint-options.component.scss'],
})
export class ComplaintOptionsComponent implements OnInit {
  title = 'Complaint items flow';
  description =
    'Decide whether the items are going to be returned back to you or if you want the customer to keep the item.';
  isLoading = true;
  isSaving = false;

  boldWords = [
    'Receive',
    'issue a refund straight away',
    'The customer can keep the damaged item',
    'I want to receive',
    'they receive a refund straight away',
  ];

  flowOptions = [
    'Issue a refund/new item for a complaint straight away without getting the complaint item back first',
    'Issue a refund/new item for a complaint only after receiving the complaint item back',
  ];

  complaintSettings: ComplaintSettings = new ComplaintSettings();

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((settings) => {
        if (settings.deadline) {
            this.complaintSettings = settings;
            this.isLoading = false;
        }
    });
  }

  setShouldBeReturned(selection: boolean): void {
    if (selection) {
      this.complaintSettings.should_item_be_returned = true;
    } else {
      this.complaintSettings.should_item_be_returned = false;
      this.complaintSettings.should_send_instant_refund = true;
    }

    this.saveSettings();
  }

  getSelectedFlow(): string {
    return this.complaintSettings.should_send_instant_refund
      ? this.flowOptions[0]
      : this.flowOptions[1];
  }

  setSelectedFlow(selection: string): void {
    selection === this.flowOptions[0]
      ? (this.complaintSettings.should_send_instant_refund = true)
      : (this.complaintSettings.should_send_instant_refund = false);

    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}
