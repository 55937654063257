<div class="dialog-header">
  <h1 class="title">
    Pricing plan history
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="mb-8">close</mat-icon>
    </button>
  </h1>
  <p class="subtitle">Here is your pricing plan history.</p>
</div>

<mat-dialog-content [ngClass]="{ loading: isLoading }">
  <div *ngIf="isLoading" class="loader">
    <app-loader size="large"></app-loader>
  </div>
  <table>
    <tr class="header-row">
      <th>Pricing plan</th>
      <th>Date started</th>
      <th>Next billing date</th>
      <th></th>
    </tr>
    <ng-container *ngIf="!isLoading">
      <tr>
        <td>
          <span class="main-text">{{
            pricePlanSubscriptions.price_plan_name
          }}</span>
        </td>
        <td>
          {{ pricePlanSubscriptions.plan_activated_at | date : "dd MMMM y" }}
        </td>
        <td>-</td>
        <td>
          <button
            class="btn btn-primary"
            (click)="goToPlans()"
            mat-dialog-close
          >
            View plans
          </button>
        </td>
      </tr>
      <ng-container
        *ngIf="pricePlanSubscriptions.price_plan_name === 'Starter'"
      >
        <tr *ngFor="let addon of getActiveAddons()">
          <td>
            <p class="m-0 main-text">Additional Carrier</p>
            <p class="m-0 subtext">+${{ addon.price }} / month</p>
          </td>
          <td>{{ addon.activated_at | date : "dd MMMM y" }}</td>
          <td>{{ addon.next_billing_date | date : "dd MMMM y" }}</td>
          <td>
            <button
              class="btn btn-primary"
              [disabled]="isCancelDisabled() || isLoading"
              (click)="toggleCancelConfirmation(addon.id)"
            >
              Cancel option
            </button>
            <p class="red-text" *ngIf="isCancelDisabled()">
              Please deactivate this carrier before cancelling this option
            </p>
          </td>
        </tr>
        <tr *ngIf="getActiveAddons().length === 0">
          <td>
            <p class="m-0 main-text">Additional Carrier</p>
            <p class="m-0 subtext">+$15 / month</p>
          </td>
          <td>-</td>
          <td>-</td>
          <td>
            <span class="subtext"
              >To add an additional carrier on top of your carrier limit please
              start activating carrier and fill in all needed information.</span
            >
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>

  <div class="cancel-confirmation" *ngIf="showCancelConfirmation">
    <p>
      Are you sure you want to disable the additional carrier? We will only
      charge the % of the days you have had this option activated at the end of
      your monthly billing cycle.
    </p>
    <div class="actions">
      <button
        class="btn btn-tertiary mr-14"
        (click)="toggleCancelConfirmation('')"
        [disabled]="isLoading"
      >
        Leave it activated
      </button>
      <button
        class="btn btn-primary"
        (click)="cancelAddon()"
        [disabled]="isLoading"
      >
        Cancel option
      </button>
    </div>
  </div>
</mat-dialog-content>
