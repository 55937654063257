import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';

@Component({
  selector: 'app-accepting-return-requests',
  templateUrl: './accepting-return-requests.component.html',
  styleUrls: ['./accepting-return-requests.component.scss'],
})
export class AcceptingReturnRequestsComponent implements OnInit {
  @Input()
  type = '';

  title = 'Reviewing and pre-approving return requests';
  description =
    'Do you want to send the shipping labels straight way or do you want to manually review the request before sending the labels? Select “automatic” if you want all your requests to be automatically pre-approved. Please note that this function is related to the initial request and not the refund, so even if you have automatically pre-approved the items, you will still have to make a final approval later in the Pending Approval stage.';
  isLoading = true;
  isSaving = false;
  onBoardingCompleted = false;

  returnOptions: string[] = [
    'Automatically pre-approve all your return requests',
    'Manually pre-approve all your return requests',
  ];

  exchangeOptions: string[] = [
    'Automatically pre-approve all your exchange requests',
    'Manually pre-approve all your exchange requests',
  ];

  returnSettings: ReturnSettings = new ReturnSettings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();

  constructor(
    private errorService: ErrorService,
    private exchangeSettingsService: ExchangeSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.setDescriptionTitle();

    const returnSettings =
      this.returnSettingsService.getSubjectReturnSettings();
    const exchangeSettings = this.exchangeSettingsService.getExchangeSubject();

    combineLatest([returnSettings, exchangeSettings]).subscribe(
      ([returnSettings, exchangeSettings]) => {
        if (
          this.returnSettingsService.hasReturnSettingsLoaded() &&
          exchangeSettings.deadline > 0
        ) {
          this.returnSettings = returnSettings;
          this.exchangeSettings = exchangeSettings;
          this.isLoading = false;
        }
      }
    );
  }

  setDescriptionTitle(): void {
    if (this.type === 'Exchange') {
      this.description = this.description.replace('return', 'exchange');
      this.title = this.title.replace('return', 'exchange');
    }
    this.description = this.bolden(this.description, [
      'manually review the return request before sending the labels',
      'manually review the exchange request before sending the labels',
      'pre-approved',
    ]);
  }

  bolden(text: string, chosenWords: string[]): string {
    chosenWords.forEach((word) => {
      text = text.replace(word, `<b>${word}</b>`);
    });
    return text;
  }

  checkOnBoarding() {
    this.returnSettings.automatically_accept_requests !== undefined
      ? (this.onBoardingCompleted = true)
      : (this.onBoardingCompleted = false);
  }

  getReturnOption(): string {
    const option: boolean = this.returnSettings.automatically_accept_requests;
    this.checkOnBoarding();

    if (option === undefined) {
      return '';
    }

    return option
      ? this.returnOptions[0]
      : this.returnOptions[1];
  }

  getExchangeOption(): string {
    return this.exchangeSettings.automatically_accept_requests
      ? this.exchangeOptions[0]
      : this.exchangeOptions[1];
  }

  setExchangeOption(value: string): void {
    this.exchangeSettings.automatically_accept_requests =
      value === this.exchangeOptions[0];
    this.saveExchangeSettings();
  }

  setReturnOption(value: string): void {
    this.returnSettings.automatically_accept_requests =
      value === this.returnOptions[0];
    this.onBoardingCompleted = true;
    this.saveSettings();
  }

  saveExchangeSettings(): void {
    this.isSaving = true;
    this.exchangeSettingsService.saveSettings(this.exchangeSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Unable to save exchange settings');
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }

  saveSettings(): void {
    this.isSaving = true;

    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: (saved: boolean) => {
          if (saved) {
            this.isSaving = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
      });
  }
}
