<app-settings-section
  id="complaints-comment"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [isToggleable]="false"
  [hasNewFeatures]="false"
>
  <div>
    <div class="text">
      <app-radio
        [options]="summaryOptions"
        name="summarySetting"
        [horizontal]="false"
        [selected]="getSelectedSummary()"
        (selectedChange)="setSelectedSummary($event)"
        [disabled]="isSaving || !complaintSettings.is_enabled"
        [boldWords]="boldWords"
      >
      </app-radio>
    </div>
  </div>
</app-settings-section>