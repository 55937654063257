import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { BringIntegrationInformation } from 'src/app/settings/models/bring-integration-information.model';
import { BringIntegrationRequest } from 'src/app/settings/models/bring-integration-request.model';
import { DeutschePostIntegrationInformation } from 'src/app/settings/models/deutsche-post-integration-information.model';
import { DeutschePostIntegrationRequest } from 'src/app/settings/models/deutsche-post-integration-request.model';
import { DhlExpressIntegrationInformation } from 'src/app/settings/models/dhl-express-integration-info.model';
import { DhlExpressIntegrationRequest } from 'src/app/settings/models/dhl-express-integration-request.model';
import { FormValues } from 'src/app/settings/models/form-values.model';
import { GlsIntegrationInformation } from 'src/app/settings/models/gls-integration-information.model';
import { GlsIntegrationRequest } from 'src/app/settings/models/gls-integration-request.model';
import { PostnordIntegrationInformation } from 'src/app/settings/models/postnord-integration-information.model';
import { PostnordIntegrationRequest } from 'src/app/settings/models/postnord-integration-request.model';
import { PricePlanSubscription } from 'src/app/settings/models/price-plan-subscription.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { SubscriptionAddon } from 'src/app/settings/models/subscription-addon.model';
import { UpsIntegrationInfo } from 'src/app/settings/models/ups-integration-info.model';
import { UpsIntegrationRequest } from 'src/app/settings/models/ups-integration-request.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { BillingService } from 'src/app/shared/services/billing.service';
import { CarrierRankingService } from 'src/app/shared/services/carrier-ranking.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { AddCarrierPopupComponent } from '../add-carrier-popup/add-carrier-popup.component';
import {
  bringPackageTypes,
  deutschePostPackageTypes,
  dhlExpressPackageTypes,
  dhlFreightPackageTypes,
  glsPackageTypes,
  postNordPackageTypes,
  upsPackageTypes,
} from '../carrier-services';
import { EssentialsCarrierLimitPopupComponent } from '../essentials-carrier-limit-popup/essentials-carrier-limit-popup.component';
import { IntegrationAlertService } from '../integration-alert.service';
import { OneCarrierPopupComponent } from '../one-carrier-popup/one-carrier-popup.component';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingSettings } from 'src/app/settings/models/shipping-settings.model';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';
import { DhlFreightIntegrationInformation } from 'src/app/settings/models/dhl-freight-integration-information.model';
import { DhlFreightIntegrationRequest } from 'src/app/settings/models/dhl-freight-integration-request.model';

@Component({
  selector: 'app-carrier-actions',
  templateUrl: './carrier-actions.component.html',
  styleUrls: ['./carrier-actions.component.scss'],
})
export class CarrierActionsComponent implements OnInit {
  @Input() shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  @Input() customerNumber = '';
  @Input() description = '';
  @Input() provider = ShippingCarrier.PostNord;
  @Input() availableServices: AvailableService[] = [];
  @Input() serviceInformations: any;
  @Input() shouldSendSms = false;
  @Input() customerCountryCode = '';
  @Input() returnLocationAddressId = '';
  @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  isSaving = false;
  shippingCarrier = ShippingCarrier;
  formValues: FormValues = new FormValues();
  enabledIds: string[] = [];
  returnSettings = new ReturnSettings();
  shippingSettings = new ShippingSettings();
  dialogRef: any;
  activeCarriers = 0;
  pricePlan: PricePlan = new PricePlan();
  pricePlanSubscriptions: PricePlanSubscription = new PricePlanSubscription();

  constructor(
    private errorService: ErrorService,
    private popupService: PopupService,
    private modalService: ModalService,
    private _dialog: MatDialog,
    private pricePlanSettingsService: PricePlanSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private shippingSettingsService: ShippingSettingsService,
    private carrierRankingService: CarrierRankingService,
    private integrationAlertService: IntegrationAlertService,
    private shippingIntegrationService: ShippingIntegrationService,
    private billingService: BillingService
  ) {}

  ngOnInit(): void {
    this.saving.subscribe((res: boolean) => (this.isSaving = res));
    this.shippingIntegrationService
      .getValuesChanged()
      .subscribe((res: FormValues) => (this.formValues = res));

    this.returnSettingsService.getSubjectReturnSettings().subscribe((settings) => {
      this.returnSettings = settings;
    });
    this.shippingSettingsService.getSubjectShippingSettings().subscribe((settings) => {
      this.shippingSettings = settings;
    })
    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe((pricePlan) => {
      this.pricePlan = pricePlan;
    });

    this.getActiveCarriers();
    this.fetchSubscriptions();
  }

  getShippingIntegrationInfo(type: string): void {
    this.shippingIntegrationService
      .getShippingIntegrationsByStatus(IntegrationStatusEnum.Active)
      .subscribe({
        next: (res: ShippingIntegration[]) => {
          if (type === 'set') {
            this.shippingIntegrationService.setUpdatedIntegration(
              this.provider,
              true
            );
            this.shippingIntegrationService.setIntegrationsInformation(res);
            return;
          }
          this.enabledIds = [];
          res.forEach((integration: ShippingIntegration) => {
            if (
              [
                IntegrationStatusEnum.Active,
                IntegrationStatusEnum.Activating,
                IntegrationStatusEnum.InfoCompleted,
              ].includes(integration.status)
            ) {
              this.enabledIds.push(integration.id);
            }
          });
          this.deactivateIntegration('one-carrier');
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to get shipping integrations!'
          );
          this.dialogRef.close();
        },
      });
  }

  getActiveCarriers(): void {
    this.shippingIntegrationService.getActiveCarriers().subscribe({
      next: (activeCarriers: number) => {
        this.activeCarriers = activeCarriers;
      },
    });
  }

  hasProccessStarted(): boolean {
    const startedIntegrations = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    return startedIntegrations && startedIntegrations.status ? true : false;
  }

  openDeactivationModal(): void {
    const infoIntegration = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        IntegrationStatusEnum.InfoCompleted === integration.status
    );
    infoIntegration && infoIntegration.status
      ? this.deactivateIntegration('edit')
      : this.modalService.open(`carrier-deactivation-${this.provider}`);
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  getPropertyValue(property: string): any {
    const informations: any = this.serviceInformations;
    return typeof informations[property] === 'string'
      ? informations[property].trim()
      : informations[property];
  }

  getGlsRequestInformation(packageType: string): GlsIntegrationRequest {
    return {
      shipping_package_type: packageType,
      portal_key: this.getPropertyValue('portalKey'),
      client_id: this.getPropertyValue('clientId'),
      client_secret: this.getPropertyValue('clientSecret'),
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
      return_location_id: this.returnLocationAddressId,
    };
  }

  getDhlExpressRequestInformation(
    packageType: string
  ): DhlExpressIntegrationRequest {
    return {
      shipping_package_type: packageType,
      account_number: this.getPropertyValue('accountNumber'),
      api_key: this.getPropertyValue('apiKey'),
      api_secret: this.getPropertyValue('apiSecret'),
      phone_nr: this.getPropertyValue('phoneNr'),
      paperless_trade: Boolean(this.getPropertyValue('paperlessTrade')),
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
      return_location_id: this.returnLocationAddressId,
    };
  }

  getPostNordRequestInformation(
    packageType: string
  ): PostnordIntegrationRequest {
    return {
      shipping_provider: this.provider,
      shipping_package_type: packageType,
      customer_number: this.getPropertyValue('customerNumber'),
      should_send_sms: this.getPropertyValue('shouldSendSms'),
      customer_country_code: this.getPropertyValue('customerCountryCode'),
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
      return_location_id: this.returnLocationAddressId,
    };
  }

  getBringRequestInformation(packageType: string): BringIntegrationRequest {
    return {
      shipping_package_type: packageType,
      api_user_id: this.getPropertyValue('apiUserId'),
      api_secret_key: this.getPropertyValue('apiSecretKey'),
      api_customer_number: this.getPropertyValue('apiCustomerNumber'),
      api_phone_number: this.getPropertyValue('apiPhoneNumber'),
      api_proforma: this.getPropertyValue('apiProforma'),
      api_org_number: this.getPropertyValue('apiOrgNumber'),
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
      return_location_id: this.returnLocationAddressId,
    };
  }

  getUpsRequestInformation(packageType: string): UpsIntegrationRequest {
    return {
      shipping_package_type: packageType,
      client_id: this.getPropertyValue('clientID'),
      client_secret: this.getPropertyValue('clientSecret'),
      account_number: this.getPropertyValue('accountNumber'),
      phone_number: this.getPropertyValue('phoneNumber'),
      state_province_code: this.getPropertyValue('stateProvince'),
      org_number: this.getPropertyValue('orgNumber'),
      paperless_trade: this.getPropertyValue('paperlessTrade'),
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
      return_location_id: this.returnLocationAddressId,
    };
  }

  getDeutschePostRequestInformation(
    packageType: string
  ): DeutschePostIntegrationRequest {
    return {
      shipping_package_type: packageType,
      customer_key: this.getPropertyValue('apiCustomerKey'),
      customer_secret: this.getPropertyValue('apiCustomerSecret'),
      account_number: this.getPropertyValue('apiCustomerNumberEKP'),
      return_location_id: this.returnLocationAddressId,
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
    };
  }

  getDhlFreightRequestInformation(
    packageType: string
  ): DhlFreightIntegrationRequest {
    return {
      shipping_package_type: packageType,
      account_number: this.getPropertyValue('accountNumber'),
      phone_number: this.getPropertyValue('phoneNumber'),
      api_proforma: this.getPropertyValue('apiProforma'),
      api_org_number: this.getPropertyValue('apiOrgNumber'),
      return_location_id: this.returnLocationAddressId,
      enabled_origin_countries:
        this.getPropertyValue('enabledCountries').get(
          packageType as ShippingPackageType
        ) ?? [],
    }
  }

  setIntegrationValuesChanged(): void {
    this.shippingIntegrationService.setValuesChanged({
      postnord: this.savedIntegration(postNordPackageTypes, 'postnord'),
      gls: this.savedIntegration(glsPackageTypes, 'gls'),
      dhlexpress: this.savedIntegration(dhlExpressPackageTypes, 'dhlexpress'),
      bring: this.savedIntegration(bringPackageTypes, 'bring'),
      ups: this.savedIntegration(upsPackageTypes, 'ups'),
      deutschePost: this.savedIntegration(
        deutschePostPackageTypes,
        'deutschePost'
      ),
      dhlfreight: this.savedIntegration(
        dhlFreightPackageTypes,
        'dhlfreight'
      )
    });
  }

  checkCarriersPricePlan(): void {
    if (
      this.activeCarriers > this.getActiveAddons().length &&
      this.pricePlan?.name.toLowerCase() === 'starter'
    ) {
      const dialogRef = this._dialog.open(OneCarrierPopupComponent, {
        width: '477px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'proceed') {
          this.openAddCarrierPopup();
        } else {
          this.isSaving = false;
        }
      });
    } else {
      this.enableIntegration(false);
    }
  }

  openAddCarrierPopup(): void {
    const dialogRef = this._dialog.open(AddCarrierPopupComponent, {
      width: '477px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'proceed') {
        this.showLoadingOverlay();
        this.enableIntegration(true);
      } else {
        this.isSaving = false;
      }
    });
  }

  enableIntegration(addExtraCarrier: boolean): void {
    this.saving.emit(true);
    const request: any[] = [];
    let requestObj:
      | GlsIntegrationRequest
      | PostnordIntegrationRequest
      | DhlExpressIntegrationRequest
      | BringIntegrationRequest
      | UpsIntegrationRequest
      | DeutschePostIntegrationRequest
      | DhlFreightIntegrationRequest;

    // Loop through services to fill the request with checked services
    this.availableServices.forEach((service: AvailableService) => {
      if (service.checked) {
        if (this.provider === ShippingCarrier.Gls) {
          requestObj = this.getGlsRequestInformation(service.packageType);
        } else if (this.provider === ShippingCarrier.PostNord) {
          requestObj = this.getPostNordRequestInformation(service.packageType);
        } else if (this.provider === ShippingCarrier.DhlExpress) {
          requestObj = this.getDhlExpressRequestInformation(
            service.packageType
          );
        } else if (this.provider === ShippingCarrier.Bring) {
          requestObj = this.getBringRequestInformation(service.packageType);
        } else if (this.provider === ShippingCarrier.Ups) {
          requestObj = this.getUpsRequestInformation(service.packageType);
        } else if (this.provider === ShippingCarrier.DhlDeutschePost) {
          requestObj = this.getDeutschePostRequestInformation(
            service.packageType
          );
        } else if (this.provider === ShippingCarrier.DhlFreight) {
          requestObj = this.getDhlFreightRequestInformation(
            service.packageType
          );
        }

        // Find id of integration if it exists
        const existingIntegration = this.shippingIntegrationInfo?.find(
          (integration: ShippingIntegration) =>
            integration.status &&
            integration.shipping_package_type === service.packageType
        );
        if (existingIntegration) {
          requestObj.id = existingIntegration.id;
        }
        request.push(requestObj);
      }
    });

    this.setIntegrationValuesChanged();
    this.shippingIntegrationService
      .createUpdateShippingIntegration(request, addExtraCarrier)
      .subscribe({
        next: (savedIntegrations) => {
          this.getShippingIntegrationInfo('set');
          this.saving.emit(false);

          this.billingService.setIsPricePlanUpdated(true);
          this.carrierRankingService.setUpdateRanking(true);
          
          const alertForUps = this.integrationAlertService.getAlertSectionForCarrier(ShippingCarrier.Ups);
          if (this.provider !== ShippingCarrier.Ups || alertForUps !== AlertSectionType.Credentials) {
            this.integrationAlertService.setAlertSectionForCarrier(
              this.provider,
              AlertSectionType.None
            );
            this.popupService.showMessageSnackBar('Integration info saved!');
            if (this.dialogRef) {
              this.dialogRef.close();
            }
          }
          const activeCarriers = savedIntegrations.filter(integration => integration.status === IntegrationStatusEnum.Active)?.length;
          this.shippingIntegrationService.setActiveCarriers(activeCarriers);
          this.getActiveCarriers();
        },
        error: (error) => {
          if (error.error.code !== 'ShippingIntegrationLimitExceeded') {
            this.errorService.showErrorSnackBar(
              'Failed to enable integration!'
            );
            return;
          }
          if (this.pricePlan?.name === 'Essentials') {
            this._dialog.open(EssentialsCarrierLimitPopupComponent, {
              width: '477px',
            });
          }
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        },
        complete: () => {
          this.saving.emit(false);
        },
      });
  }

  deactivateIntegration(type: string): void {
    this.isSaving = true;
    // Get the id of integrations to be deactivated
    let arrayOfId: string[] = [];
    if (type !== 'one-carrier') {
      this.shippingIntegrationInfo?.forEach(
        (integration: ShippingIntegration) => {
          arrayOfId.push(integration.id);
        }
      );
    } else {
      arrayOfId = this.enabledIds;
    }

    this.saving.emit(true);
    this.shippingIntegrationService
      .deactiveShippingIntegration(arrayOfId.toString())
      .subscribe({
        next: (activeIntegrationsCount) => {
          if (activeIntegrationsCount === 0) {
            this.returnSettings.autogenerated_slips = false;
            this.returnSettingsService.setReturnSettings(this.returnSettings);
            this.shippingSettings.automatically_track_returns = false;
            this.shippingSettingsService.setShippingSettings(this.shippingSettings);
          }
          this.saving.emit(false);
          this.popupService.showMessageSnackBar(
            type === 'edit' ? 'Editing enabled!' : 'Integration disabled!'
          );
          this.carrierRankingService.setUpdateRanking(true);
          this.billingService.setIsPricePlanUpdated(true);
          this.getShippingIntegrationInfo('set');
          this.closeModal();
          if (type === 'one-carrier') {
            this.checkCarriersPricePlan();
          }
          this.shippingIntegrationService.setActiveCarriers(activeIntegrationsCount);
          this.getActiveCarriers();
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to disable integration!');
          this.dialogRef.close();
        },
        complete: () => {
          this.closeModal();
          this.saving.emit(false);
        },
      });
  }

  disableActivation(): boolean {
    let isFormInvalid = false;
    const glsServices = this.availableServices.filter(
      (service: AvailableService) =>
        service.shipping_carrier === ShippingCarrier.Gls
    );

    const dhlExpressServices = this.availableServices.filter(
      (service: AvailableService) =>
        service.shipping_carrier === ShippingCarrier.DhlExpress
    );

    const bringServices = this.availableServices.filter(
      (service: AvailableService) =>
        service.shipping_carrier === ShippingCarrier.Bring
    );

    const upsServices = this.availableServices.filter(
      (service: AvailableService) =>
        service.shipping_carrier === ShippingCarrier.Ups
    );

    const dhlDeutschePostServices = this.availableServices.filter(
      (service: AvailableService) =>
        service.shipping_carrier === ShippingCarrier.DhlDeutschePost
    );

    const dhlFreightServices = this.availableServices.filter(
      (service: AvailableService) => 
        service.shipping_carrier === ShippingCarrier.DhlFreight
    );

    if (glsServices.length > 0) {
      isFormInvalid = this.isGlsInvalid();
    } else if (dhlExpressServices.length > 0) {
      isFormInvalid = this.isDhlExpressInvalid();
    } else if (bringServices.length > 0) {
      isFormInvalid = this.isBringInvalid();
    } else if (upsServices.length > 0) {
      isFormInvalid = this.isUpsInvalid();
    } else if (dhlDeutschePostServices.length > 0) {
      isFormInvalid = this.isDhlDeutschePostInvalid();
    } else if (dhlFreightServices.length > 0) {
      isFormInvalid = this.isDhlFreightInvalid();
    } else {
      isFormInvalid = this.isPostNordInvalid();
    }

    const activeServices = this.availableServices.find(
      (service: AvailableService) => service.checked
    );
    return isFormInvalid || this.isSaving || !activeServices;
  }

  isUpsInvalid(): boolean {
    return (
      (this.serviceInformations instanceof UpsIntegrationInfo &&
        !this.serviceInformations.clientID) ||
      !this.serviceInformations.clientSecret ||
      !this.serviceInformations.accountNumber ||
      !this.serviceInformations.phoneNumber ||
      !this.serviceInformations.orgNumber
    );
  }

  isPostNordInvalid(): boolean {
    return (
      (this.serviceInformations instanceof PostnordIntegrationInformation &&
        !this.serviceInformations.customerNumber) ||
      !this.serviceInformations.customerCountryCode
    );
  }

  isGlsInvalid(): boolean {
    return (
      (this.serviceInformations instanceof GlsIntegrationInformation &&
        !this.serviceInformations.clientId) ||
      !this.serviceInformations.clientSecret ||
      !this.serviceInformations.portalKey
    );
  }

  isDhlExpressInvalid(): boolean {
    return (
      (this.serviceInformations instanceof DhlExpressIntegrationInformation &&
        !this.serviceInformations.accountNumber) ||
      !this.serviceInformations.apiKey ||
      !this.serviceInformations.apiSecret ||
      !this.serviceInformations.phoneNr
    );
  }

  isBringInvalid(): boolean {
    if (this.serviceInformations.apiProforma) {
      return !this.serviceInformations.apiOrgNumber;
    }
    return (
      (this.serviceInformations instanceof BringIntegrationInformation &&
        !this.serviceInformations.apiUserId) ||
      !this.serviceInformations.apiSecretKey ||
      !this.serviceInformations.apiCustomerNumber ||
      !this.serviceInformations.apiPhoneNumber
    );
  }

  isDhlDeutschePostInvalid(): boolean {
    return (
      (this.serviceInformations instanceof DeutschePostIntegrationInformation &&
        !this.serviceInformations.apiCustomerKey) ||
      !this.serviceInformations.apiCustomerSecret ||
      !this.serviceInformations.apiCustomerNumberEKP
    );
  }

  isDhlFreightInvalid(): boolean {
    return(
      (this.serviceInformations instanceof DhlFreightIntegrationInformation &&
        !this.serviceInformations.accountNumber) ||
      !this.serviceInformations.phoneNumber ||
      (this.serviceInformations.apiProforma && !this.serviceInformations.apiOrgNumber)
    );
  }

  savedIntegration(
    packageTypes: ShippingPackageType[],
    property: keyof FormValues
  ): boolean {
    return this.shippingIntegrationInfo?.some(
      (integration: ShippingIntegration) =>
        packageTypes.includes(integration.shipping_package_type)
    )
      ? false
      : this.formValues[property];
  }

  showLoadingOverlay(): void {
    this.dialogRef = this._dialog.open(LoaderComponent, {
      panelClass: 'transparent',
      disableClose: true,
    });
    this.dialogRef.componentInstance.size = 'large';
  }

  getButtonDeactivateText(): string {
    const infoIntegration = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        IntegrationStatusEnum.InfoCompleted === integration.status
    );

    return infoIntegration && infoIntegration.status
      ? 'Edit info'
      : 'Deactivate carrier';
  }

  showSaveButton(): boolean {
    const alertForCarrier =
      this.integrationAlertService.getAlertSectionForCarrier(
        this.provider
      );
    return alertForCarrier !== AlertSectionType.None;
  }

  fetchSubscriptions(): void {
    this.billingService.getSubjectPricePlanSubscriptions().subscribe((res) => {
      this.pricePlanSubscriptions = res;
    });
  }

  getActiveAddons(): SubscriptionAddon[] {
    return this.pricePlanSubscriptions.addons.filter(
      (addon) => addon.is_active
    );
  }
}
