<app-settings-section
  id="accepting-return-requests"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [title]="title"
  [description]="description"
  [onBoardingStepCompleted]="onBoardingCompleted"
>
  <div *ngIf="type === 'Return'" class="radio_buttons mt-24">
    <app-radio
      name="returnOptions"
      [options]="returnOptions"
      [horizontal]="false"
      [selected]="getReturnOption()"
      (selectedChange)="setReturnOption($event)"
      [disabled]="isSaving"
      [boldWords]="['Automatically', 'Manually']"
    >
    </app-radio>
  </div>

  <div *ngIf="type === 'Exchange'" class="radio_buttons mt-24">
    <div [ngClass]="{ disabled: isSaving }">
      <app-radio
        name="exchanges"
        [options]="exchangeOptions"
        [horizontal]="false"
        [selected]="getExchangeOption()"
        (selectedChange)="setExchangeOption($event)"
        [disabled]="isSaving"
        [boldWords]="['Automatically', 'Manually']"
      >
      </app-radio>
    </div>
  </div>
</app-settings-section>
