import { Component, OnInit } from '@angular/core';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-complaint-image',
  templateUrl: './complaint-image.component.html',
  styleUrls: ['./complaint-image.component.scss'],
})
export class ComplaintImageComponent implements OnInit {
  title = 'Upload an image';
  description =
    'Choose whether you want to make it optional or mandatory for a customer to upload an image and how many of them you want to see.';
  note = 'You have unsaved changes - make sure to save them before moving on';
  isLoading = true;
  isSaving = false;

  boldWords = [
    'mandatory',
    'optional',
    'DON’T allow',
  ];

  imageOptions = [
    'Make it optional for a customer to upload a picture of the item',
    'Make it mandatory for a customer to upload a picture of the item',
    'DON’T allow a customer to upload a picture of a returned item',
  ];

  nrOfPhotosOptions = [
    '1 photo',
    '2 photos',
    '3 photos',
    '4 photos',
    '5 photos',
  ];
  minNumberOfPhotos = [...this.nrOfPhotosOptions];
  maxNumberOfPhotos = [...this.nrOfPhotosOptions];

  complaintSettings: ComplaintSettings = new ComplaintSettings();
  PremiumOptions = PremiumOptions;

  imageOption = this.complaintSettings.image_upload_option;
  minNumberOfImages = this.complaintSettings.min_number_of_images;
  maxNumberOfImages = this.complaintSettings.max_number_of_images;

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((settings) => {
        if (settings.deadline) {
            this.complaintSettings = settings;
            this.imageOption = settings.image_upload_option;
            this.maxNumberOfImages = settings.max_number_of_images;
            this.minNumberOfImages = settings.min_number_of_images;
            this.isLoading = false;
        }
    });
  }

  getSelectedImage(): string {
    switch (this.complaintSettings.image_upload_option) {
      case PremiumOptions.Optional:
        return this.imageOptions[0];
      case PremiumOptions.Mandatory:
        return this.imageOptions[1];
      case PremiumOptions.NotAllowed:
        return this.imageOptions[2];
    }
  }

  setSelectedImage(selection: string): void {
    switch (selection) {
      case this.imageOptions[0]:
        this.imageOption = PremiumOptions.Optional;
        this.minNumberOfImages = 0;
        break;
      case this.imageOptions[1]:
        this.imageOption = PremiumOptions.Mandatory;
        break;
      case this.imageOptions[2]:
        this.imageOption = PremiumOptions.NotAllowed;
        this.minNumberOfImages = 0;
        break;
    }
  }

  setMinPhotos(selection: string): void {
    this.minNumberOfImages = parseInt(selection);
  }

  setMaxPhotos(selection: string): void {
    this.minNumberOfPhotos = this.nrOfPhotosOptions.filter(
      (x) => x.split(' ')[0].trim() < selection
    );
    this.maxNumberOfImages = parseInt(selection);
    if (
      this.minNumberOfImages >
      this.maxNumberOfImages
    ) {
      this.minNumberOfImages =
        this.maxNumberOfImages;
    }
  }

  getPhotos(type: string): string {
    return type === 'min'
      ? this.appendSuffix(this.minNumberOfImages.toString())
      : this.appendSuffix(
          this.maxNumberOfImages.toString()
        );
  }

  appendSuffix(text: string): string {
    return text.charAt(0) === '1'
      ? text.concat(' photo')
      : text.concat(' photos');
  }

  showAlert(): boolean {
    return !(this.complaintSettings.image_upload_option === this.imageOption &&
    this.complaintSettings.min_number_of_images === this.minNumberOfImages &&
    this.complaintSettings.max_number_of_images === this.maxNumberOfImages);
  }

  saveSettings(): void {
    this.isSaving = true;

    if (this.imageOption != PremiumOptions.NotAllowed &&
        this.minNumberOfImages > this.maxNumberOfImages) {
        this.errorService.showErrorSnackBar("Cannot set minimum amount higher than maximum amount of images.");
        this.isSaving = false;
        return;
    }

    this.complaintSettings.image_upload_option = this.imageOption;
    this.complaintSettings.max_number_of_images = this.maxNumberOfImages;
    this.complaintSettings.min_number_of_images = this.minNumberOfImages;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
          this.isSaving = false;
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save complaint image settings.');
          this.isSaving = false;
        }
      });
  }
}