<div class="right pt-50 pl-20" *ngIf="return && return?.order_name">
  <ng-container>
    <h1 class="font-24 text-almost-black title">Customer info</h1>
    <div class="right__general pt-16">
      <p>{{ return.customer.first_name }} {{ return.customer.last_name }}</p>
      <p *ngIf="return.customer?.phone">{{ return.customer?.phone }}</p>
      <p>{{ return.customer.email }}</p>

      <div class="right__general">
        <h4 class="sub-title mt-24 mb-8">Items coming from:</h4>
        <p>
          {{ return.shipping_address.country }}, {{return.shipping_address.city}}
        </p>
      </div>
    </div>

    <app-chat
      [returnID]="return.id"
      [comments]="comments"
      title="Internal chat"
      description="No comments yet."
      chatTitle="Add a comment"
    >
    </app-chat>

    <div class="right__timeline">
      <h1 class="font-24 text-almost-black title">
        Order timeline<app-loader
          *ngIf="isLoadingTimeline"
          class="loader"
          size="medium"
        ></app-loader>
      </h1>
      <div class="right__timeline-stepper">
        <mat-stepper
          [ngClass]="{ blur: isLoadingTimeline }"
          #stepper
          orientation="vertical"
          [linear]="true"
        >
          <mat-step
            state="timeline-state"
            *ngFor="let timeline of timelinesStatuses"
            [completed]="getCompleted(timeline.step)"
          >
            <ng-template matStepLabel>
              <span class="timeline-title">
                <span
                  *ngIf="
                    !hasComplaints(return.items) &&
                    ![0, 1].includes(timeline.step)
                  "
                  >{{ return.type }}</span
                >
                {{ timeline.title }}
                <mat-icon
                  *ngIf="getCompleted(timeline.step)"
                  class="expand-timeline"
                  >{{
                    stepper.selectedIndex !== timeline.step
                      ? "expand_more"
                      : "expand_less"
                  }}</mat-icon
                >
              </span>
              <span
                class="timeline-date"
                *ngIf="
                  getCompleted(timeline.step) &&
                  getTimelineDate(timeline.dateProperty)
                "
              >
                {{ getTimelineDate(timeline.dateProperty) | date : "dd MMMM" }}
                at
                {{ getTimelineDate(timeline.dateProperty) | date : "H:mm" }}
              </span>
            </ng-template>

            <div *ngIf="shouldDisplayDeadlineBadge(timeline)">              
              <p class="timeline-deadline">
                {{ getDeadlineBadgeText() }}
              </p>
            </div>

            <div *ngIf="showItemsTable(timeline)">
              <p class="font-500 font-14 text-almost-black m-0 mb-5">
                {{ getTotalItems() }} items
              </p>
              <div class="items-container">
                <p class="item mt-0" *ngFor="let item of return.items">
                  <span class="picture-name">
                    <mat-icon class="item-image mr-8" *ngIf="!item.image_source"
                      >image</mat-icon
                    >
                    <img
                      *ngIf="item.image_source"
                      [src]="item.image_source"
                      [alt]="item.name"
                      class="item-image mr-8"
                    />
                    <span class="item-name">{{ item.name }}</span>
                  </span>

                  <span class="item-price" *ngIf="timeline.step === 0"
                    >{{ getTotalPriceForItem(item) }}
                    {{ return.currency }}</span
                  >
                  <span
                    class="ripple"
                    [ngClass]="addClass(item.status, item)"
                    *ngIf="
                      timeline.step === 2 ||
                      timeline.step === 5 ||
                      timeline.step === 4
                    "
                    >{{ displayStatus(item.status, item) }}</span
                  >
                </p>
              </div>
            </div>

            <div class="deliver-info font-14" *ngIf="timeline.step === 1">
              <p class="mb-8 font-500">Delivered to:</p>
              <p class="deliver-text">
                {{ return.customer.first_name }}
                {{ return.customer.last_name }}
              </p>
              <p class="deliver-text" *ngIf="return.customer?.phone">
                {{ return.customer?.phone }}
              </p>
              <p class="deliver-text">{{ return.customer.email }}</p>
            </div>
            <div *ngIf="timeline.step === 4 || timeline.step === 2">
              <p
                class="return-action"
                *ngIf="
                  (return.status === ReturnStatusEnum.PackageReceived &&
                    timeline.step === 4) ||
                  ((return.status === ReturnStatusEnum.RequestReceived ||
                    timeline.showOnRefund === isComplaintRefund) &&
                    timeline.step === 2)
                "
              >
                Awaiting action
              </p>
            </div>
            <div
              *ngIf="
                timeline.step === 3 &&
                timeline.showOnReturn === isComplaintReturnItems &&
                hasComplaints(return.items)
              "
            >
              <p class="complaint-text">
                Refund status
                <span class="complaint-text-status"
                  ><mat-icon>{{
                    refundStatusText(
                      return.items,
                      isResolved,
                      hasComplaintNewItem(),
                      allItemsDeclined(),
                      rejectedItemsCount()
                    ).icon
                  }}</mat-icon>
                  {{
                    refundStatusText(
                      return.items,
                      isResolved,
                      hasComplaintNewItem(),
                      allItemsDeclined(),
                      rejectedItemsCount()
                    ).text
                  }}</span
                >
              </p>
            </div>
          </mat-step>

          <ng-template matStepperIcon="timeline-state"> </ng-template>
        </mat-stepper>
      </div>
    </div>
  </ng-container>
</div>
