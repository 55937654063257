<div id="price-plans">
  <app-price-plan
    class="price-plan ml-15"
    *ngFor="let pricePlan of pricePlans"
    [name]="pricePlan.name"
    [price]="getDisplayPrice(pricePlan)"
    [priceUnit]="getPriceUnit(pricePlan)"
    [tagLine]="pricePlan.description"
    [isSelected]="currentPricePlanId === pricePlan.id"
    (selected)="setPricePlan(pricePlan)"
    [plansSelected]="checkForActivePlans()"
    [currentPricePlan]="currentPricePlan"
  >
  </app-price-plan>
</div>

<!-- Expandable section -->
<div>
  <div class="benefits">
    <p>List of benefits</p>
    <p class="text-primary" (click)="showFullContent = !showFullContent">
      Expand benefits list
      <img
        class="arrow"
        [ngClass]="{ rotate: showFullContent }"
        src="../assets/images/icons/chevron-down.png"
      />
    </p>
  </div>
  
  <table class="full-benefits" [ngClass]="{ expanded: showFullContent }">
    <div class="benefits-details">
      <thead>
        <th *ngFor="let benefit of pricePlans[0].benefits;"> {{ benefit.name }} </th>
      </thead>      
      <tbody>
        <td *ngFor="let pricePlan of pricePlans;">
          <tr *ngFor="let benefit of pricePlan.benefits">
            <ng-container>
              <p *ngIf="benefit.value" class="description"> 
                {{ benefit.value }} </p>
              <p  *ngIf="benefit.notes" class="notes">
                {{ benefit.notes }}
              </p>
            </ng-container>
            <ng-container *ngIf="benefit.icon">
              <img
                class="mr-8"
                [ngClass]="{ disabled: benefit.icon === 'X' }"
                [src]="getImgSource(benefit.icon)"
              />
            </ng-container>
          </tr>
        </td>
      </tbody>
    </div>
  </table>
</div>
