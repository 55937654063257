import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { ListViewComponent } from './list-view/list-view.component';
import { ReturnTableComponent } from './list-view/return-table/return-table.component';
import { OnboardingAccordionComponent } from './onboarding-accordion/onboarding-accordion.component';
import { ReturnsRoutingModule } from './returns-routing.module';
import { ArchiveModalComponent } from './sidenav/archive-modal/archive-modal.component';
import { ImagesPreviewComponent } from './sidenav/images-preview/images-preview.component';
import { ItemsTableComponent } from './sidenav/left-content/items-table/items-table.component';
import { LeftContentComponent } from './sidenav/left-content/left-content.component';
import { RefundAmountComponent } from './sidenav/left-content/refund-amount/refund-amount.component';
import { RejectItemsModalComponent } from './sidenav/left-content/reject-items-modal/reject-items-modal.component';
import { ReturnShippingComponent } from './sidenav/left-content/return-shipping/return-shipping.component';
import { SidenavHeaderComponent } from './sidenav/left-content/sidenav-header/sidenav-header.component';
import { RightContentComponent } from './sidenav/right-content/right-content.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { GiftCardComponent } from './sidenav/left-content/gift-card/gift-card.component';
import { NewFeaturesModalComponent } from './list-view/new-features-modal/new-features-modal.component';
import { PayAsYouGoModalComponent } from './list-view/pay-as-you-go-modal/pay-as-you-go-modal.component';

@NgModule({
  declarations: [
    ListViewComponent,
    ReturnTableComponent,
    SidenavComponent,
    LeftContentComponent,
    RightContentComponent,
    ArchiveModalComponent,
    SidenavHeaderComponent,
    ReturnShippingComponent,
    RefundAmountComponent,
    ItemsTableComponent,
    OnboardingAccordionComponent,
    ImagesPreviewComponent,
    RejectItemsModalComponent,
    GiftCardComponent,
    NewFeaturesModalComponent,
    PayAsYouGoModalComponent,
  ],
  imports: [
    CommonModule,
    ReturnsRoutingModule,
    ComponentsModule,
    FormsModule,
    SharedModule,
  ],
  exports: [OnboardingAccordionComponent],
})
export class ReturnsModule {}
