import { Component, OnInit } from '@angular/core';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-complaint-image-instructions',
  templateUrl: './complaint-image-instructions.component.html',
  styleUrls: ['./complaint-image-instructions.component.scss'],
})
export class ComplaintImagesInstructionsComponent implements OnInit {
  title = 'Image instructions';
  description =
    'Here you can include additional instructions for how the customers should take a picture of the item.';
  isLoading = true;
  isSaving = false;

  complaintSettings: ComplaintSettings = new ComplaintSettings();

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((settings) => {
        if (settings.deadline) {
            this.complaintSettings = settings;
            this.isLoading = false;
        }
    });
  }

  isEnabled(): boolean {
    return (this.complaintSettings.is_enabled && 
    this.complaintSettings.image_upload_option !== PremiumOptions.NotAllowed);
  }

  toggleInstructions(state: boolean): void {
    this.complaintSettings.has_image_instructions = state;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}