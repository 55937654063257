import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalService } from 'src/app/components/modal/modal.service';
import { OrderResponse } from 'src/app/models/order-response.model';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { DeadlineEntry } from 'src/app/settings/models/deadline-entry.model';
import { ExtendedDeadlineRequest } from 'src/app/settings/models/deadline/extended-deadline-request.model';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { ExtendedDeadline } from 'src/app/settings/models/extended-deadline.model';
import { IDictionary } from 'src/app/shared/dictionary.model';
import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { ExtendByOption } from 'src/app/shared/enums/extend-by-option.enum';
import { ExtendedDeadlineStatus } from 'src/app/shared/enums/extended-deadline-status.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ExtendedDeadlineService } from 'src/app/shared/services/settings/extended-deadline.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';

@Component({
  selector: 'app-extend-deadline',
  templateUrl: './extend-deadline.component.html',
  styleUrls: ['./extend-deadline.component.scss'],
})
export class ExtendDeadlineComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  isViewing = false;
  
  title = 'Extending deadline on a specific order';
  description =
    'Extend the deadline on a specific order, so that the customer can still submit a request even after the general deadline has passed.';

  activeDeadlines: ExtendedDeadline[] = [];
  expiredDeadlines: ExtendedDeadline[] = [];
  activeDeadlineEntries: DeadlineEntry[] = [];
  expiredDeadlineEntries: DeadlineEntry[] = [];
  
  onlyNumberPattern: RegExp = new RegExp('^[0-9]+$');
  deadlineForm = this.formBuilder.group({
    order_name: ['', [Validators.maxLength(30), Validators.pattern(this.onlyNumberPattern)]],
    extend_by: [ExtendByOption.OneWeek, [Validators.required]],
    extend_return: [false],
    extend_exchange: [false],
    extend_complaint: [false]
  });
  deadlineOnEdit: ExtendedDeadlineRequest = new ExtendedDeadlineRequest();
  
  extendByOptions: IDictionary<string> = { 
    OneWeek: '1 week',
    TwoWeeks: '2 weeks', 
    OneMonth: '1 month', 
    Indefinite: 'Indefinite (until I deactivate it)'
  };
  extendByDisplayOptions = Object.values(this.extendByOptions);
  hasSelectedTime = false;

  requestTypes = Object.values(ReturnReasonType);
  exchangeSettings = new ExchangeSettings();
  complaintSettings = new ComplaintSettings();

  isSearchOrderValid = false;
  searchOrders: OrderResponse[] = [];
  selectedOrder: OrderResponse = new OrderResponse();
  orderInfo = '';

  @ViewChild('clickHoverMenuTrigger')
  clickHoverMenuTrigger!: MatMenuTrigger;

  constructor(
    private extendedDeadlineService: ExtendedDeadlineService,
    private returnSettingsService: ReturnSettingsService,
    private exchangeSettingsService: ExchangeSettingsService,
    private complaintSettingsService: ComplaintSettingsService,
    private orderService: OrderService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.extendedDeadlineService.getExtendedDeadlines().subscribe({
      next: (deadlines: ExtendedDeadline[]) => {
        if (this.returnSettingsService.hasReturnSettingsLoaded()) {
          this.activeDeadlines = deadlines.filter(extendedDeadline => extendedDeadline.status !== ExtendedDeadlineStatus.Expired);
          this.expiredDeadlines = deadlines.filter(extendedDeadline => extendedDeadline.status === ExtendedDeadlineStatus.Expired);

          this.activeDeadlineEntries = this.populateDeadlineEntries(this.activeDeadlines);
          this.expiredDeadlineEntries = this.populateDeadlineEntries(this.expiredDeadlines);

          this.isLoading = false;
        }
      },
      error: () => {
        this.errorService.showErrorSnackBar(
          'Unable to load extended deadlines'
        );
      }
    });

    this.exchangeSettingsService
    .getExchangeSubject()
    .subscribe((exchangeSettings: ExchangeSettings) => {
      this.exchangeSettings = exchangeSettings;
    });

    this.complaintSettingsService
    .getComplaintSettingsSubject()
    .subscribe((complaintSettings: ComplaintSettings) => {
      this.complaintSettings = complaintSettings;
    })
  }

  populateDeadlineEntries(extendedDeadlines: ExtendedDeadline[]): DeadlineEntry[] {
    const entries: DeadlineEntry[] = extendedDeadlines.map(extendedDeadline => 
      ({id: extendedDeadline.original_order_id,
        name: extendedDeadline.name, 
        info: `${Object.keys(extendedDeadline.extended_to).join("s, ")}s are extended for ${this.getExtendedForText(extendedDeadline)}`,
        status: this.stringifyStatus(extendedDeadline.status)
      })
    );
    return entries;
  }

  stringifyStatus(status: ExtendedDeadlineStatus): string {
    switch(status){
      case ExtendedDeadlineStatus.PartiallyActive:
        return 'Partially active';
      default:
        return status.toString();
    }
  }

  getExtendedForText(extendedDeadline: ExtendedDeadline): string {
    if (extendedDeadline.deadline.toString() === '-1') {
      return 'an indefinite amount of time';
    }

    if (extendedDeadline.deadline.toString() === '1') {
      return `${extendedDeadline.deadline} ${extendedDeadline.deadline_time_period.slice(0, extendedDeadline.deadline_time_period.length - 1)}`;
    }

    return `${extendedDeadline.deadline} ${extendedDeadline.deadline_time_period}`;
  }

  getAlertType(): string {
    return this.isViewing ? 'Note' : 'Information';
  }

  getAlertIcon(): string {
    return this.isViewing ? '../assets/images/icons/alert-triangle.png' : '../assets/images/icons/help-circle.png';
  }

  openViewModal(deadlineId: string): void {
    this.isViewing = true;
    this.deadlineOnEdit = new ExtendedDeadlineRequest();
    this.selectedOrder = new OrderResponse();

    const deadline = 
      this.activeDeadlines
        .find(x => x.original_order_id?.toString() === deadlineId.toString()) ?? 
      this.expiredDeadlines
        .find(x => x.original_order_id?.toString() === deadlineId.toString());

    if (deadline) {
      this.deadlineOnEdit = this.map(deadline);
      this.populateOrderWithNewDeadlines(deadline, this.selectedOrder);
    }
    this.orderInfo = this.deadlineOnEdit.name;
    this.deadlineOnEdit.request_types.forEach(requestType => {
      this.checkRequestType(requestType);
    });
    this.modalService.open('extended-deadline');
  }

  map(extendedDeadline: ExtendedDeadline): ExtendedDeadlineRequest {
    return {
      extend_by: this.statusMapping(Number.parseInt(extendedDeadline.deadline), extendedDeadline.deadline_time_period),
      order_created_at: new Date(),
      order_shipped_at: new Date(),
      original_order_id: extendedDeadline.original_order_id ?? '',
      request_types: Object.keys(extendedDeadline.extended_to) as ReturnReasonType[],
      name: extendedDeadline.name
    }
  }

  populateOrderWithNewDeadlines(deadline: ExtendedDeadline, order: OrderResponse): void {
    const complaintDeadline = deadline.extended_to[ReturnReasonType.Complaint];
    if (complaintDeadline !== undefined) {
      order.complaint_deadline = complaintDeadline;
    }

    const exchangeDeadline = deadline.extended_to[ReturnReasonType.Exchange];
    if (exchangeDeadline !== undefined) {
      order.exchange_deadline = exchangeDeadline;
    }

    const returnDeadline = deadline.extended_to[ReturnReasonType.Return];
    if (returnDeadline !== undefined) {
      order.return_deadline = returnDeadline;
    }
  }

  statusMapping(deadline: number, timePeriod: DeadlineTimePeriod): ExtendByOption {
    if (timePeriod === DeadlineTimePeriod.Months) {
      return ExtendByOption.OneMonth;
    }
    if (deadline === 14) {
      return ExtendByOption.TwoWeeks;
    }
    if (deadline === -1) {
      return ExtendByOption.Indefinite;
    }
    return ExtendByOption.OneWeek;
  }

  checkRequestType(requestType: ReturnReasonType): void {
    const name = `extend_${requestType.toLowerCase()}`;
    this.deadlineForm.controls[name].setValue(true);
  }

  openCreateModal(): void {
    this.isViewing = false;
    this.modalService.open('extended-deadline');
  }

  openDeactivateModal(): void {
    this.modalService.open('deactivate-extended-deadline');
    this.closeModal('extended-deadline');
  }

  openDeleteModal(deadlineId: string): void {
    const deadline = this.activeDeadlines.find(x => x.original_order_id === deadlineId) ?? this.expiredDeadlines.find(x => x.original_order_id === deadlineId);
    if (deadline) {
      this.deadlineOnEdit = this.map(deadline);
      this.populateOrderWithNewDeadlines(deadline, this.selectedOrder);
    }

    this.closeModal('extended-deadline');
    this.modalService.open('delete-extended-deadline');
  }

  searchOrder(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value;
    const hasOnlyNumbers = searchValue, regexp = this.onlyNumberPattern;
    
    if (searchValue === this.deadlineForm.value['order_name'] && this.isSearchOrderValid) {
      this.clickHoverMenuTrigger.openMenu();
      return;
    }
    this.deadlineForm.value['order_name'] = searchValue;

    if (!regexp.test(hasOnlyNumbers)) {
      this.isSearchOrderValid = false;
      this.searchOrders = [];
      this.clickHoverMenuTrigger.closeMenu();
      return;
    }
    this.isSearchOrderValid = true;

    if (searchValue.length > 1) {
      this.orderService.getOrdersByName(searchValue)
      .subscribe({
        next: (res: OrderResponse[]) => {
          this.searchOrders = res;
          if (res.length > 0) {
            this.clickHoverMenuTrigger.openMenu();
          }
        },
        error: () => {
          this.clickHoverMenuTrigger.closeMenu();
        }});
    }

    if (this.searchOrders.length < 1) {
      this.clickHoverMenuTrigger.closeMenu();
    }
  }

  getSearchHtml(order: OrderResponse): string {
    let searchValue = this.orderInfo;
    let prefix = '';
    let rest = '';

    const searchStartIndex = order.order_name.indexOf(searchValue);
    if (searchStartIndex > 0) {
      prefix = order.order_name.substring(0, searchStartIndex);
    }
    if (searchStartIndex + searchValue.length < order.order_name.length) {
      rest = order.order_name.substring(searchStartIndex + searchValue.length);
    }

    if (searchStartIndex === -1) {
      prefix = order.order_name;
      searchValue = '';
      rest = '';
    }
    
    return `<p class="gray">${prefix}</p>
            <p class="bold">${searchValue}</p>
            <p>${rest}</p>
            <p class="gray"> &nbsp;• </p>
            <p class="gray">${order.customer_name}</p>`;
  }

  selectOrder(order: OrderResponse | undefined): void {
    if (this.isViewing) {
      return;
    }
    if (order !== undefined) {
      this.selectedOrder = order;
      return;
    }
    this.selectedOrder = new OrderResponse();
    if (this.searchOrders.length > 0) {
      this.clickHoverMenuTrigger.openMenu();
    }
  }

  setTimeOption(selection: string): void {
    this.hasSelectedTime = true;
    switch(selection) {
      case this.extendByOptions[ExtendByOption.TwoWeeks]:
        this.deadlineOnEdit.extend_by = ExtendByOption.TwoWeeks;
        return;
      case this.extendByOptions[ExtendByOption.OneMonth]:
        this.deadlineOnEdit.extend_by = ExtendByOption.OneMonth;
        return;
      case this.extendByOptions[ExtendByOption.Indefinite]:
        this.deadlineOnEdit.extend_by = ExtendByOption.Indefinite;
        return;
      default:
        this.deadlineOnEdit.extend_by = ExtendByOption.OneWeek;
        return;
    }
  }

  getTimeOption(): string {
    if (this.deadlineOnEdit.original_order_id) {
      return this.extendByOptions[this.deadlineOnEdit.extend_by];
    }
    return '';
  }

  getDisplayName(requestType: ReturnReasonType): string {
    return requestType.toString() + "s";
  }

  isRequestTypeActive(requestType: ReturnReasonType): boolean {
    switch (requestType) {
      case ReturnReasonType.Exchange:
        return this.exchangeSettings.is_enabled;
      case ReturnReasonType.Complaint:
        return this.complaintSettings.is_enabled;
      default:
        return true;
    }
  }

  getRequestDeadlineText(requestType: ReturnReasonType): string {
    let date = '';
    switch (requestType) {
      case ReturnReasonType.Exchange:
        date = this.selectedOrder.exchange_deadline.substring(0, 10);
        break;
      case ReturnReasonType.Complaint:
        date = this.selectedOrder.complaint_deadline.substring(0, 10);
        break;
      case ReturnReasonType.Return:
        date = this.selectedOrder.return_deadline.substring(0, 10);
        break;
    }

    let information = '';
    if (date && date.length > 0) {
      information = ' •  Original deadline ';
      if (this.isViewing) {
        information = ' • Extended until ';
        if (this.deadlineOnEdit.extend_by === ExtendByOption.Indefinite) {
          information = ' • Extended indefinitely';
          date = '';
        }
      }
    }
    else if (this.isViewing) {
      information = ' • Not extended';
    }

    return information + date;
  }

  updateRequestTypes(event: MatCheckboxChange, requestType: ReturnReasonType): void {
    const currentRequestTypes = this.deadlineOnEdit.request_types;
    let updatedRequestTypes = [...currentRequestTypes, requestType];

    if (!event.checked) {
      const index = this.deadlineOnEdit.request_types.indexOf(requestType);
      this.deadlineOnEdit.request_types.splice(index, 1);
      updatedRequestTypes = [...currentRequestTypes];
    }

    this.deadlineOnEdit.request_types = updatedRequestTypes;
  }

  checkDisabled(): boolean {
    return this.selectedOrder.order_id.length === 0 || !this.hasSelectedTime || this.deadlineOnEdit.request_types.length === 0;
  }

  canDeactivate(): boolean {
    if (this.deadlineOnEdit.original_order_id !== undefined) {
      const expiredDeadlineMatch = this.expiredDeadlines.find(deadline => deadline.original_order_id === this.deadlineOnEdit.original_order_id);
      return expiredDeadlineMatch === undefined;
    }
    return true;
  }

  saveChanges(): void {
    this.isSaving = true;
    this.deadlineOnEdit.original_order_id = this.selectedOrder.order_id.toString();
    this.deadlineOnEdit.name = `Order ${this.selectedOrder.order_name}`;
    this.deadlineOnEdit.order_created_at = new Date(this.selectedOrder.order_created_at);
    this.deadlineOnEdit.order_shipped_at = new Date(this.selectedOrder.order_shipped_at);

    this.extendedDeadlineService
    .createNewDeadline(this.deadlineOnEdit)
    .subscribe({
      next: (res: ExtendedDeadline) => {
        this.activeDeadlines = [res, ...this.activeDeadlines];
        this.activeDeadlineEntries = this.populateDeadlineEntries(this.activeDeadlines);
        this.extendedDeadlineService.updateDeadlineSubject(res, true);

        this.deadlineOnEdit = new ExtendedDeadlineRequest();
        this.selectedOrder = new OrderResponse();

        this.close();
      },
      error: (res) => {
        if (res.error?.code === 'MultipleExtendedDeadlineError') {
          this.modalService.open('duplicant-extended-deadline');
          this.isSaving = false;
          return;
        }
        this.errorService.showErrorSnackBar(
          res.error?.errors?.Deadline[0] ?? 'Unable to add deadline'
        );
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
        this.close();
      },
    });
  }

  deactivateDeadline(): void {
    this.isSaving = true;
    if (this.deadlineOnEdit.original_order_id !== undefined) {
      this.extendedDeadlineService.archiveDeadline(this.deadlineOnEdit.original_order_id)
      .subscribe({
        next: (res: ExtendedDeadline) => {
          const archivedDeadline = this.activeDeadlines.find(deadline => deadline.original_order_id === this.deadlineOnEdit.original_order_id);
          const index = this.activeDeadlines.findIndex(x => x.original_order_id === res.original_order_id);
          if (index > -1 && archivedDeadline !== undefined) {
            this.activeDeadlines.splice(index, 1);
            this.activeDeadlineEntries = this.populateDeadlineEntries(this.activeDeadlines);
            this.extendedDeadlineService.updateDeadlineSubject(archivedDeadline, false);
            
            this.expiredDeadlines = [res, ...this.expiredDeadlines];
            this.expiredDeadlineEntries = this.populateDeadlineEntries(this.expiredDeadlines);
            this.extendedDeadlineService.updateDeadlineSubject(res, true);
          }
          this.closeModal('deactivate-extended-deadline');
          this.clear();
        },
        error: (res) => {
          this.errorService.showErrorSnackBar(
            res.error?.errors?.Deadline[0] ?? 'Unable to deactivate deadline'
          );
          this.isSaving = false;
        },
        complete: () => {
          this.isSaving = false;
          this.closeModal('deactivate-extended-deadline');
        },
      });
    }
  }

  deleteDeadline(): void {
    const errorMessage = 'Unable to delete deadline';
    this.isSaving = true;

    if (this.deadlineOnEdit.original_order_id !== undefined) {
      this.extendedDeadlineService.deleteDeadline(this.deadlineOnEdit.original_order_id)
      .subscribe({
        next: (success: boolean) => {
          if (!success) {
            this.stopSavingProcessWithError(errorMessage);
            return;
          }

          const removedDeadline = 
            this.activeDeadlines.find(deadline => deadline.original_order_id === this.deadlineOnEdit.original_order_id) ??
            this.expiredDeadlines.find(deadline => deadline.original_order_id === this.deadlineOnEdit.original_order_id);
          if (removedDeadline === undefined) {
            this.stopSavingProcessWithError(errorMessage);
            return;
          }
          this.extendedDeadlineService.updateDeadlineSubject(removedDeadline, false);

          const activeDeadlinesIndex = this.activeDeadlines.findIndex(x => x.original_order_id === this.deadlineOnEdit.original_order_id);
          if (activeDeadlinesIndex > 0) {
            this.activeDeadlines.splice(activeDeadlinesIndex, 1);
            this.activeDeadlineEntries = this.populateDeadlineEntries(this.activeDeadlines);
          }

          const expiredDeadlinesIndex = this.expiredDeadlines.findIndex(x => x.original_order_id === this.deadlineOnEdit.original_order_id);
          if (expiredDeadlinesIndex > 0) {
            this.expiredDeadlines.splice(expiredDeadlinesIndex, 1);
            this.expiredDeadlineEntries = this.populateDeadlineEntries(this.expiredDeadlines);
          }
          this.clear();
        },
        error: (res) => {
          this.stopSavingProcessWithError(res.error?.errors?.Deadline[0] ?? errorMessage);
        },
        complete: () => {
          this.isSaving = false;
          this.closeModal('delete-extended-deadline');
        },
      });
    }
  }

  stopSavingProcessWithError(error: string): void {
    this.errorService.showErrorSnackBar(error);
    this.isSaving = false;
  }

  close(): void {
    this.clear();
    this.modalService.close('extended-deadline');
  }

  clear(): void {
    this.selectedOrder = new OrderResponse();
    this.deadlineForm.reset(this.selectedOrder);
    this.orderInfo = '';
    this.deadlineOnEdit = new ExtendedDeadlineRequest();
    this.hasSelectedTime = false;
    this.isViewing = false;
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  goBack(closeId: string): void {
    if (this.isViewing) {
      this.openViewModal(this.deadlineOnEdit.original_order_id ?? "");
    }
    this.closeModal(closeId);
  }

  openExistingDeadline(): void {
    this.closeModal('duplicant-extended-deadline');
    this.openViewModal(this.selectedOrder.order_id);
  }
}