import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { PricePlanSubscription } from 'src/app/settings/models/price-plan-subscription.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { SubscriptionAddon } from 'src/app/settings/models/subscription-addon.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { BillingService } from 'src/app/shared/services/billing.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { PricePlanHistoryPopupComponent } from '../price-plan-history-popup/price-plan-history-popup.component';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-price-plan-carriers',
  templateUrl: './price-plan-carriers.component.html',
  styleUrls: ['./price-plan-carriers.component.scss'],
})
export class PricePlanCarriersComponent implements OnInit {
  currentPricePlanId = '';
  pricePlan: PricePlan = new PricePlan();
  isLoading = false;
  activeCarriers = 0;
  pricePlanSubscriptions: PricePlanSubscription = new PricePlanSubscription();
  allowedCarrierNumber = '0';
  allowedCarrierText = '';

  constructor(
    private billingService: BillingService,
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private shippingIntegrationService: ShippingIntegrationService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getSettings();
    this.getIsPricePlanUpdated();
    this.getPricePlanSubscriptions('init');
  }

  getPricePlan(): void {
    this.billingService
      .getPricePlans()
      .pipe(first((x) => x.length != 0))
      .subscribe({
        next: (pricePlans) => {
          this.billingService.setPricePlans(pricePlans);
          this.pricePlan =
            pricePlans.find((plan) => plan.id === this.currentPricePlanId) ??
            new PricePlan();
          this.allowedCarrierNumber = this.mapAllowedCarriers('number');
          this.allowedCarrierText = this.mapAllowedCarriers('text');
          this.isLoading = false;
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load price plans');
          this.isLoading = false;
        },
      });
  }

  getSettings(): void {
    this.isLoading = true;

    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe((pricePlan) => {
      this.currentPricePlanId = pricePlan?.id ?? '';
      if (this.currentPricePlanId) {
        this.getPricePlan();
        this.getActiveCarriers();
      }
    });
  }

  getPricePlanSubscriptions(type: string): void {
    this.billingService.getPricePlanSubscriptions().subscribe({
      next: (res) => {
          this.billingService.setPricePlanSubscriptions(res);
          this.pricePlanSubscriptions = res;
          this.isLoading = false;
          this.allowedCarrierNumber = this.mapAllowedCarriers('number');
          if (type === 'update') {
            this.getPricePlan();
        }
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load subscriptions');
        this.isLoading = false;
      },
    });
  }

  mapAllowedCarriers(type: string): string {
    const pricePlanName = this.pricePlan?.name.toLowerCase();
    if (pricePlanName === 'starter') {
      return (this.getActiveAddons().length + 1).toString();
    } else if (pricePlanName === 'essentials') {
      return '3';
    } else if (pricePlanName === 'pro') {
      if (type === 'text') {
        return 'Unlimited';
      }
      return '∞';
    }
    return '0';
  }

  getActiveCarriers(): void {
    this.shippingIntegrationService
      .getShippingIntegrationsByStatus(IntegrationStatusEnum.Active)
      .subscribe((res: ShippingIntegration[]) => {
        const groupedItems = res.reduce(
          (acc: any, item: ShippingIntegration) => {
            const key = item.shipping_provider;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {}
        );
        this.activeCarriers = Object.keys(groupedItems).length;
      });
  }

  openPricePlanHistoryPopup(): void {
    this._dialog.open(PricePlanHistoryPopupComponent, {
      width: '1000px',
    });
  }

  extraCarrierAdded(): number {
    if (this.pricePlan?.name.toLowerCase() !== 'starter') {
      return 0;
    }
    return this.getActiveAddons().length;
  }

  getIsPricePlanUpdated(): void {
    this.billingService.getIsPricePlanUpdated().subscribe((res) => {
      if (res) {
        this.isLoading = true;
        this.getPricePlan();
        this.getPricePlanSubscriptions('update');
        this.getActiveCarriers();
        this.billingService.setIsPricePlanUpdated(false);
      }
    });
  }

  getActiveAddons(): SubscriptionAddon[] {
    return this.pricePlanSubscriptions.addons.filter(
      (addon) => addon.is_active
    );
  }

  getPricePlanName(): string {
    const planName = this.pricePlan?.name;
    return planName.length > 0 ? planName : 'No plan';
  }
}
