<app-modal id="pay-as-you-go-modal" size="new-features" [dismissable]="false">
    <div class="header">
      <div class="title">
        <h1>Updates and new features</h1>
      </div>
      <p class="date">{{ date }}</p>
    </div>
  
    <div class="body">
      <h1 class="mt-24">New Price plan!</h1>
      <h4>Starter</h4>
      <p>
        We've updated our pricing plans. Since you're using Pay-As-You-Go (PAYG) 
        or haven’t selected a plan, you need to switch. PAYG is being replaced 
        by the "Starter" plan at $29/month.
      </p>
      <p><b>Key Changes:</b></p>
      <ul>
        <li><b>Starter Plan:</b> $29/month includes 10 returns, exchanges, or complaints.</li>
        <li><b>Extra Cases:</b> $15 for each additional 10 requests.</li>
        <li><b>Included Features:</b> SMS notifications and shipping labels are now included.</li>
      </ul>
      <p><b>Please note: Until you select a new plan, your app will be frozen.</b></p>
      <p>For assistance or questions, email us at <a href="mailto:support@returbo.io">support&#64;returbo.io</a> and we will be happy to help.</p>
    </div>
    <app-select-plan></app-select-plan>
  </app-modal>