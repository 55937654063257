import { DeadlineTimePeriod } from './enums/deadline-time-period.enum';
import { ExtendByOption } from './enums/extend-by-option.enum';
import { ReturnReasonType } from './enums/return-reason-type.enum';

export class Utils {
  static findDeadlineEnumByValue(value: string): DeadlineTimePeriod {
    return (
      (Object.values(DeadlineTimePeriod).find(
        (enumValue) => enumValue === value
      ) as DeadlineTimePeriod) ?? DeadlineTimePeriod.Days
    );
  }

  static findRequestTypeEnumByValue(value: string): ReturnReasonType {
    return (
      (Object.values(ReturnReasonType).find(
        (enumValue) => enumValue === value
      ) as ReturnReasonType) ?? ReturnReasonType.Return
    );
  }

  static findExtendByEnumByValue(value: string): ExtendByOption {
    return (
      (Object.values(ExtendByOption).find(
        (enumValue) => enumValue === value
      ) as ExtendByOption) ?? ExtendByOption.OneWeek
    );
  }
}
