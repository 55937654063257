<div class="dialog-header">
  <h1 class="font-24 text-almost-black font-700 mt-0">
    Add another carrier for $15/month?
  </h1>
</div>

<mat-dialog-content>
  <p class="font-normal font-14 text-almost-black">
    Based on your current <b>Starter</b> plan, you only have one carrier
    included in your plan.
  </p>
  <p class="font-normal font-14 text-almost-black">
    If you want to activate more than 1 carrier – you can do so for
    <b>an additional monthly charge of US $15</b>. We will include this charge
    and bill you the amount at the end of your monthly billing cycle.
  </p>
  <p class="font-normal font-14 text-almost-black">
    Otherwise, you can always upgrade to another pricing plan that includes more
    shipping carriers.
  </p>
  <div class="checkbox-container" [ngClass]="{ selected: agree }">
    <mat-checkbox color="accent" [(ngModel)]="agree"> </mat-checkbox>
    <span
      >I understand that a monthly charge of $15 will be included at the end of
      my monthly billing cycle and agree to pay and proceed.</span
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <span class="link" (click)="goToPlans()" mat-dialog-close
    >See pricing plans</span
  >
  <div class="buttons">
    <button class="btn btn-tertiary btn-md mr-16" mat-dialog-close>Back</button>
    <button
      class="btn btn-primary btn-md mr-0"
      mat-dialog-close="proceed"
      [disabled]="!agree"
    >
      Activate carrier
    </button>
  </div>
</mat-dialog-actions>
