import { Component, OnInit } from '@angular/core';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-complaint-toggle',
  templateUrl: './complaint-toggle.component.html',
  styleUrls: ['./complaint-toggle.component.scss'],
})
export class ComplaintToggleComponent implements OnInit {
  title = 'Enable Complaints';
  description =
    'Level up with an advanced complaint feature. Craft a personalized complaint flow. Customers upload images and comments on damaged items. You can tailor your complaint flow to immediately refund your customer for the damaged item or have them send it back.';
  isLoading = true;
  isSaving = false;

  bannerText = 
    'Please make sure you add complaint reasons in the Return Reasons block.';
  bannerStats = {
    text: '',
    icon: '',
    imgIcon: '',
    type: 'Note',
    size: 'small',
    showClose: true,
    boldWords: ['Return Reasons'],
  };

  complaintSettings: ComplaintSettings = new ComplaintSettings();

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((settings) => {
        if (settings.deadline) {
            if (settings.is_enabled) {
                this.bannerStats.text = this.bannerText;
            }
            this.complaintSettings = settings;
            this.isLoading = false;
        }
    });
  }

  toggleComplaints(): void {
    this.complaintSettings.is_enabled = !this.complaintSettings.is_enabled;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
          this.bannerStats.text = this.complaintSettings.is_enabled ? this.bannerText : '';
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save complaint settings.');
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}