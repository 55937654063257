<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [isToggleable]="true"
  (sectionEnabled)="enablePremiumReturns()"
  [switchStatus]="premiumReturnSettings.is_enabled"
  [isSliderDisabled]="!premiumReturnAccess"
  [hasNewFeatures]="false"
  feature="New feature"
>

  <div
    class="mt-8"
    *ngIf="
      !premiumReturnAccess
    "
  >
    <app-alert
      icon="help_outline"
      type="Information"
      size="small"
      [showCloseButton]="true"
      text="If you have the Starter plan, this feature is not accessible to you, this feature is included in Essentials and Pro plans. For more details, please visit the payment plan page."
    >
    </app-alert>
  </div>

  <div class="toggle-view" [ngClass]="{ 'adjust-margin': !showContent }">
    <p (click)="showContent = !showContent" class="expand-text">
      {{
        showContent
          ? "Collapse to hide configuration"
          : "Expand to show configuration"
      }}
    </p>
    <img
      [ngClass]="showContent ? 'arrow' : 'rotate-arrow'"
      src="../assets/images/icons/chevron-up.png"
    />
  </div>

  <ng-container *ngIf="showContent">
    <div>
      <h4 class="title">Upload image</h4>
      <p class="description">
        Choose whether it is optional or mandatory for a customer to upload an
        image and if so, how many of them for each return.
      </p>

      <div class="text">
        <app-radio
          [options]="imageOptions"
          name="premiumImageOptions"
          [horizontal]="false"
          [selected]="getSelectedImage()"
          (selectedChange)="setSelectedImage($event)"
          [disabled]="isSaving || !premiumReturnSettings.is_enabled"
          [boldWords]="boldWords"
        >
        </app-radio>
      </div>

      <div
        class="select-section"
        *ngIf="premiumReturnSettings.image_upload_option !== PremiumOptions.NotAllowed"
      >
        <ng-container
          *ngIf="premiumReturnSettings.image_upload_option === PremiumOptions.Optional"
        >
          Customer can upload
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !premiumReturnSettings.is_enabled"
              [options]="maxNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMaxPhotos($event)"
              [selectedOption]="getPhotos('max')"
            ></app-animated-dropdown>
          </span>
        </ng-container>

        <ng-container
          *ngIf="premiumReturnSettings.image_upload_option === PremiumOptions.Mandatory"
        >
          Customer must upload
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !premiumReturnSettings.is_enabled"
              [options]="minNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMinPhotos($event)"
              [selectedOption]="getPhotos('min')"
            ></app-animated-dropdown>
          </span>

          per returned item and can upload up to
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !premiumReturnSettings.is_enabled"
              [options]="maxNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMaxPhotos($event)"
              [selectedOption]="getPhotos('max')"
            ></app-animated-dropdown>
          </span>
        </ng-container>
      </div>
    </div>

    <div>
      <h4 class="title">Image instructions</h4>
      <p class="description">
        Here you can include additional instructions for how the customers
        should take a picture of the item.
      </p>

      <app-checkbox
        size="medium"
        (checkedChange)="toggleInstructions($event)"
        [checked]="premiumReturnSettings.has_image_instructions"
        [disabled]="!premiumReturnSettings.is_enabled"
        ><span class="text">
          Include instructions for the image that will appear to the customer
        </span>
      </app-checkbox>

      <h4 class="title mb-5">Instructions</h4>

      <input
        (focusout)="saveSettings()"
        type="text"
        [disabled]="
          !premiumReturnSettings.has_image_instructions ||
          isSaving ||
          !premiumReturnSettings.is_enabled
        "
        placeholder="e.g. We need the photo of the sides and the front."
        [(ngModel)]="premiumReturnSettings.image_instructions_field"
      />
      <p class="information">
        Please keep it to 60 characters. Visible to the customer.
      </p>
    </div>

    <div>
      <h4 class="title">Comment on returned item</h4>
      <p class="description">
        Choose whether you want to make it optional or mandatory for a customer
        to write a comment about an item they are returning.
      </p>

      <div class="text">
        <app-radio
          [options]="summaryOptions"
          name="premiumSummarySetting"
          [horizontal]="false"
          [selected]="getSelectedSummary()"
          (selectedChange)="setSelectedSummary($event)"
          [disabled]="isSaving || !premiumReturnSettings.is_enabled"
          [boldWords]="boldWords"
        >
        </app-radio>
      </div>
    </div>
  </ng-container>
</app-settings-section>
