export const statusMessages = [
  {
    type: 'ReturnCreatedEmail',
    message: {
      text: 'Request submitted',
      status: 'Request submitted',
    },
  },
  {
    type: 'PostNordEmail',
    message: {
      text: 'Shipping slip sent – PostNord only',
      status: 'Incoming',
    },
  },
  {
    type: 'DhlFreightEmail',
    message: {
      text: 'Shipping slip sent – DHL Freight only',
      status: 'Incoming',
    },
  },
  {
    type: 'DhlDeutschePostEmail',
    message: {
      text: 'Shipping slip sent – Deutsche Post only',
      status: 'Incoming',
    },
  },
  {
    type: 'GlsEmail',
    message: {
      text: 'Shipping slip sent – GLS only',
      status: 'Incoming',
    },
  },
  {
    type: 'UpsEmail',
    message: {
      text: 'Shipping slip sent – UPS only',
      status: 'Incoming',
    },
  },
  {
    type: 'DhlExpressEmail',
    message: {
      text: 'Shipping slip sent – DHL Express only',
      status: 'Incoming',
    },
  },
  {
    type: 'BringEmail',
    message: {
      text: 'Shipping slip sent – Bring only',
      status: 'Incoming',
    },
  },
  {
    type: 'ShippingSlipSkippedEmail',
    message: {
      text: 'No slip attached',
      status: 'Incoming',
    },
  },
  {
    type: 'UploadedShippingSlipEmail',
    message: {
      text: 'Shipping slip sent – Upload shipping slip',
      status: 'Incoming',
    },
  },
  {
    type: 'PackageReceivedEmail',
    message: {
      text: 'Items received',
      status: 'Pending Approval',
    },
  },
  {
    type: 'RefundApprovedEmail',
    message: {
      text: 'Request resolved - all items accepted',
      status: 'Resolved',
    },
  },
  {
    type: 'PartialRefundApprovedEmail',
    message: {
      text: 'Request resolved - items partially accepted',
      status: 'Resolved',
    },
  },
  {
    type: 'RefundDeniedEmail',
    message: {
      text: 'Request resolved - all items rejected',
      status: 'Resolved',
    },
  },
];
