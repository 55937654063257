<app-settings-section
  id="complaints-images"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  (sectionEnabled)="complaintSettings.is_enabled"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
<div>
    <div
    *ngIf="showAlert()"
    class="mb-12"
    >
        <app-alert
        type="Note"
        [showCloseButton]="true"
        [text]="note"
        imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>
    </div>
    <div class="text">
      <app-radio
        [options]="imageOptions"
        name="imageSettings"
        [horizontal]="false"
        [selected]="getSelectedImage()"
        (selectedChange)="setSelectedImage($event)"
        [disabled]="isSaving || !complaintSettings.is_enabled"
        [boldWords]="boldWords"
      >
      </app-radio>
    </div>

    <div
      class="select-section mb-16"
      *ngIf="
        imageOption !== PremiumOptions.NotAllowed
      "
    >
      <ng-container
        *ngIf="
          imageOption === PremiumOptions.Optional
        "
      >
        Customer can upload
        <span class="mr-10 ml-10">
          <app-animated-dropdown
            [disabled]="isSaving || !complaintSettings.is_enabled"
            [options]="maxNumberOfPhotos"
            placeholder="Select"
            size="wide"
            (selectedChange)="setMaxPhotos($event)"
            [selectedOption]="getPhotos('max')"
          ></app-animated-dropdown>
        </span>
      </ng-container>

      <ng-container
        *ngIf="
          imageOption === PremiumOptions.Mandatory
        "
      >
        Customer must upload
        <span class="mr-10 ml-10">
          <app-animated-dropdown
            [disabled]="isSaving || !complaintSettings.is_enabled"
            [options]="minNumberOfPhotos"
            placeholder="Select"
            size="wide"
            (selectedChange)="setMinPhotos($event)"
            [selectedOption]="getPhotos('min')"
          ></app-animated-dropdown>
        </span>

        per returned item and can upload up to
        <span class="mr-10 ml-10">
          <app-animated-dropdown
            [disabled]="isSaving || !complaintSettings.is_enabled"
            [options]="maxNumberOfPhotos"
            placeholder="Select"
            size="wide"
            (selectedChange)="setMaxPhotos($event)"
            [selectedOption]="getPhotos('max')"
          ></app-animated-dropdown>
        </span>
      </ng-container>
    </div>
    <app-new-button
        buttonStyle="confirm"
        (click)="saveSettings()"
        [disabled]="!complaintSettings.is_enabled || isLoading || isSaving"
    >
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M17 21V13H7V21' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M7 3V8H15' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>
        &nbsp;
        Save image settings
    </app-new-button>
  </div>
</app-settings-section>