<app-settings-section
  id="complaints-item-flow"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
  <div>
    <app-checkbox
      size="small"
      (checkedChange)="setShouldBeReturned($event)"
      [checked]="complaintSettings.should_item_be_returned"
      [disabled]="isSaving || isLoading"
      >I want to receive the complaint items back to me
    </app-checkbox>
    <p class="information mt-12 mb-12">
      If you don't have this option selected then the complaint items that
      you approve to be refunded <b>won’t be sent back</b> to you, the
      <b>customer will keep the items</b> and
      <b>you will issue a refund</b> as soon as you approve the complaint.
    </p>
  </div>

  <div class="text ml-20">
    <app-radio
      [options]="flowOptions"
      name="flowSetting"
      [horizontal]="false"
      [selected]="getSelectedFlow()"
      (selectedChange)="setSelectedFlow($event)"
      [disabled]="
        isSaving ||
        !complaintSettings.is_enabled ||
        !complaintSettings.should_item_be_returned
      "
      [boldWords]="boldWords"
    >
    </app-radio>
  </div>
</app-settings-section>
