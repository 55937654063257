<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [planRestrictions]="planRestrictions"
>
  <div class="radio-btn">
    <app-radio
      name="automatic-tracking"
      [options]="options"
      [horizontal]="false"
      [selected]="getSelectedValue()"
      (selectedChange)="setSelectedValue($event)"
      [disabled]="isLoading || isSaving || disabled"
    >
    </app-radio>
  </div>
</app-settings-section>

<app-tracking-error-popup
  (actionTaken)="disableTracking()"
></app-tracking-error-popup>
