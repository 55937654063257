import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeadlineEntry } from 'src/app/settings/models/deadline-entry.model';

@Component({
  selector: 'app-deadline-entry',
  templateUrl: './deadline-entry.component.html',
  styleUrls: ['./deadline-entry.component.scss'],
})
export class DeadlineEntryComponent {
  @Input()
  deadline: DeadlineEntry = new DeadlineEntry();
  @Input()
  canView = false;
  @Input()
  canEdit = false;

  @Output()
  view: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  edit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  delete: EventEmitter<boolean> = new EventEmitter<boolean>();

  getStatus(status: string): string {
    return status.replace(' ', '-').toLowerCase();
  }

  emit(type: string): void {
    if (type === 'view') {
      this.view.emit(true);
    }
    else if (type === 'edit') {
      this.edit.emit(true);
    }
    else if (type === 'delete') {
      this.delete.emit(true);
    }
  }
}