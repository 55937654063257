export enum NotificationEmailTypeEnum {
  ReturnCreatedEmail = 'ReturnCreatedEmail',
  ShippingSlipSkippedEmail = 'ShippingSlipSkippedEmail',
  DhlDeutschePostEmail = 'DhlDeutschePostEmail',
  UpsEmail = 'UpsEmail',
  PostNordEmail = 'PostNordEmail',
  GlsEmail = 'GlsEmail',
  DhlExpressEmail = 'DhlExpressEmail',
  DhlFreightEmail = 'DhlFreightEmail',
  BringEmail = 'BringEmail',
  UploadedShippingSlipEmail = 'UploadedShippingSlipEmail',
  PackageReceivedEmail = 'PackageReceivedEmail',
  RefundApprovedEmail = 'RefundApprovedEmail',
  PartialRefundApprovedEmail = 'PartialRefundApprovedEmail',
  RefundDeniedEmail = 'RefundDeniedEmail',
}
