import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OrderResponse } from "src/app/models/order-response.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})

export class OrderService {

    constructor(private httpClient: HttpClient) {}

    getOrdersByName(orderName: string): Observable<OrderResponse[]> {
        return this.httpClient.get<OrderResponse[]>(
            `${environment.apiUrl}/api/Return/orders?orderName=${orderName}&limit=5`
        );
    }
}