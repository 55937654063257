import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ModalService } from 'src/app/components/modal/modal.service';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { FeatureEnum } from 'src/app/shared/enums/feature.enum';

@Component({
  selector: 'app-form-customization',
  templateUrl: './form-customization.component.html',
  styleUrls: ['./form-customization.component.scss'],
})
export class FormCustomizationComponent implements OnInit {
  title = 'Customer return form customization';
  description =
    'Manage your return form, logo, and email notifications to create a custom return experience that suits your brand. ';
  info =
    'You can choose to display the name of your company and your logo on the digital return form. The name of your store displayed will be the same as you have provided in the contact information block.';
  isLoading = true;
  isSaving = false;
  restoredDefault = false;
  isLoadingFeatureAccess = true;
  formCustomizationAccess = false;

  formSettings: ReturnFormSettings = new ReturnFormSettings();
  fileName = '';

  note =
    'Please note that “Returbo logo” and “Digital Return Form color palette” are only available to users with a Pro plan.';

  constructor(
    private modalService: ModalService,
    private errorService: ErrorService,
    private popupService: PopupService,
    private formSettingsService: FormSettingsService,
    private router: Router,
    private featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.formSettingsService.getSubjectFormSettings().subscribe({
      next: (res) => {
        if (res.secondary_color) {
          this.formSettings = res;
          const splitLength =
            this.formSettings.form_logo_path?.split('/').length || 0;
          this.fileName =
            this.formSettings.form_logo_path?.split('/')[splitLength - 1];
          this.isLoading = false;
        }
      },
      error: () => {
        this.isLoading = false;
        this.errorService.showErrorSnackBar('Unable to Return Form Settings');
      }
    });

    this.getFormCustomizationAccess();
  }

  public getLogoSource(): string {
    return this.formSettings?.form_logo_path ?? '';
  }

  hasLogo(): boolean {
    return (
      this.formSettings?.form_logo_path != null &&
      this.formSettings.form_logo_path.length != 0
    );
  }

  previewLogo(): void {
    this.modalService.open('logo');
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  removeLogo(): void {
    this.fileName = '';
    this.formSettings.form_logo_path = '';
    this.saveSettings();
  }

  fileSelected(event: Event): void {
    const fileInputElement = event.target as HTMLInputElement;
    if (
      fileInputElement === null ||
      !fileInputElement.files ||
      fileInputElement.files.length !== 1
    ) {
      return;
    }

    const selectedFile = fileInputElement.files[0];
    this.fileName = fileInputElement.files[0].name;
    this.isSaving = true;

    this.formSettingsService.uploadLogo(selectedFile).subscribe({
      next: (formSettings) => {
        this.formSettings = formSettings;
        this.isSaving = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to upload logo');
        this.isSaving = false;
      },
    });
  }

  restoreDefault(): void {
    this.formSettings.primary_color = '#DE4066';
    this.formSettings.text_color = '#FFFFFF';
    this.formSettings.secondary_color = '#FFF5F7';
    this.popupService.showMessageSnackBar('Restored default values.');
    this.restoredDefault = true;
  }

  applyColor(): void {
    this.formCustomizationAccess
      ? this.saveSettings(true)
      : this.modalService.open('missing-pro-plan');
  }

  goToPage(elementID: string) {
    this.modalService.dismiss();
    this.router.navigate(['/settings/account']);
    setTimeout(() => {
      document.getElementById(elementID)?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  }

  checkDisabled(
    primary: boolean | null,
    text: boolean | null,
    secondary: boolean | null
  ): boolean {
    return this.restoredDefault
      ? !this.restoredDefault
      : !(primary || text || secondary);
  }

  getFormCustomizationAccess() {
    this.isLoadingFeatureAccess = true;
    this.featureService.getFeatureAccess(FeatureEnum.FormColorCustomization).subscribe({
      next: (res) => {
        this.formCustomizationAccess = res;
        this.isLoadingFeatureAccess = false;
      },
      error: () => {
        this.isLoadingFeatureAccess = false;
        this.errorService.showErrorSnackBar('Unable to check access for Form Customization');
      }
    })
  }

  saveSettings(colorSettings?: boolean): void {
    this.isSaving = true;

    this.formSettingsService.saveFormSettings(this.formSettings).subscribe({
      next: () => {
        this.formSettingsService.setFormSettingsSubject(this.formSettings);
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed save settings.');
      },
      complete: () => {
        this.isSaving = false;
        if (colorSettings) {
          this.popupService.showMessageSnackBar(
            'Successfully updated color preferences.'
          );
        }
      },
    });
  }
}
