
<div class="deadline">
    <div class ="deadline-info">
        <p class="name">{{ deadline.name }}</p>
        <p class="info ml-8">{{ deadline.info }}</p>
    </div>
    <div class="actions">
        <div class="status-badge {{ getStatus(deadline.status) }}">
            {{ deadline.status }}
        </div>
        <img
            src="../assets/images/svg/view.svg"
            alt="View deadline"
            class="ml-8 mr-8 primary-action"
            *ngIf="canView"
            (click)="emit('view')"
        />
        <img
            src="../assets/images/svg/edit.svg"
            alt="Edit deadline"
            class="ml-8 mr-8 primary-action"
            *ngIf="canEdit"
            (click)="emit('edit')"
        />
        <img
            src="../assets/images/svg/delete.svg"
            alt="Delete deadline"
            (click)="emit('delete')"
        />
    </div>
</div>