<div class="wrap">
  <app-deadline-list
        [isSaving] = "isSaving"
        title = "Special deadlines"
        [displayAddAction] = "true"
        addActionTitle = "Add a special deadline"
        [disableAddAction]="planRestrictions.length !== 0 || !checkDisabledDeadlines(type)"
        [canEdit] = "true"
        (edit)="editDeadline($event, type)"
        (add)="newDeadline(type)"
        (delete)="removeDeadline($event)"
        [deadlines]="filteredDeadlinesEntries"
        [planRestrictions]="planRestrictions"
    ></app-deadline-list>
</div>

<app-modal
  size="x-small-rectangle"
  id="deadline-{{ type }}"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Add a special deadline</h1>
  <p class="deadline-description mt-0 mb-0">
    Select if you want to extend or shorten the deadline for certain periods.
    E.g. Christmas could be a good time to extend them.
  </p>

  <form [formGroup]="deadlineForm">
    <div class="deadline-input-wrap mb-16 mt-16">
      <p class="deadline-label">Deadline name</p>
      <input
        formControlName="name"
        [ngClass]="{
          'character-limit-exceeded': checkNameLength()
        }"
        [ngModel]="deadlineOnEdit.name"
        placeholder="e.g. Christmas period"
        type="text"
        maxlength="200"
      />
      <span
        class="character-limit mt-2"
        [ngClass]="{ error: checkNameLength() }"
        >The deadline name cannot be longer than 30 characters.</span
      >
    </div>

    <div class="mb-16">
      <p class="deadline-label">Duration period</p>
      <app-date-picker></app-date-picker>
    </div>

    <div class="deadline-input-wrap mb-24">
      <p class="deadline-label">New deadline</p>

      <div class="options">
        <input
          formControlName="deadline"
          [ngModel]="deadlineOnEdit.deadline"
          class="deadline-input"
          type="number"
          placeholder="30"
          onKeyPress="if(this.value.length >= 2) return false;"
        />
        <app-animated-dropdown
          placeholder="Select"
          (selectedChange)="setTimeOption($event)"
          [selectedOption]="getTimeOption()"
          [options]="timeOptions"
          class="ml-8"
        >
        </app-animated-dropdown>
        <span class="text ml-8">is the new deadline on the items </span>
      </div>
      <span class="text mt-8">
        bought during the indicated duration period.
      </span>
    </div>
    <div class="deadline-actions mt-24">
      <app-new-button buttonStyle="cancel" (click)="close()"
        >Go back</app-new-button
      >
      <app-new-button [disabled]="checkDisabled()" (click)="saveChanges()"
        >Save deadline</app-new-button
      >
    </div>
  </form>
</app-modal>

<app-modal
  size="x-small-rectangle"
  id="special-deadlines-overlap"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Special deadlines overlap</h1>
  <p class="deadline-description mt-0 mb-0">
    Your new special deadline dates seem to conflict with another set of
    deadlines for the same request type. To prevent overlapping dates for the
    same request type, please select alternative dates that do not overlap.
  </p>

  <div class="deadline-actions mt-24">
    <app-new-button (click)="closeModal('special-deadlines-overlap')">
      Understood
    </app-new-button>
  </div>
</app-modal>

<app-modal
  size="x-small-rectangle"
  id="limit-reached-error"
  [dismissable]="false"
>
  <h1 class="deadline-title mt-0 mb-8">Special deadline limit reached</h1>
  <p class="deadline-description mt-0 mb-0">
    The <b>Essentials</b> plan includes one <b>(1)</b> special deadline per
    request type. Need more deadlines? Consider upgrading to our
    <b>Pro</b> payment plan, which offers an <b>unlimited</b> number of special
    deadlines for each request type.
  </p>
  <p>Would you like to go to <b>Account settings</b> now?</p>

  <div class="deadline-actions mt-24">
    <app-new-button
      buttonStyle="cancel"
      (click)="closeModal('limit-reached-error')"
    >
      Back
    </app-new-button>
    <app-new-button (click)="goToAccountSettings()">Proceed</app-new-button>
  </div>
</app-modal>
