<div class="wrap p-relative">
  <div *ngIf="isToggleable" class="slider">
    <app-slider
      [disabled]="isLoading || isSliderDisabled"
      [hideText]="true"
      (toggleChange)="toggleFeature($event)"
      [checked]="switchStatus"
    ></app-slider>
    <span class="ml-16"
      >Function is turned <b>{{ switchStatus ? "ON" : "OFF" }}</b></span
    >
  </div>

  <div *ngIf="bannerStats.text" class="mt-24">
    <app-alert
      icon="{{ bannerStats.icon }}"
      imgIcon="{{ bannerStats.imgIcon }}"
      type="{{ bannerStats.type }}"
      size="{{ bannerStats.size }}"
      [showCloseButton]="bannerStats.showClose"
      [innerText]="bolden(bannerStats.text, bannerStats.boldWords)"
    >
    </app-alert>
  </div>

  <div class="header">
    <h2>
      {{ title }} <img src="{{ logoSource }}" />
      <span *ngIf="hasNewFeatures" class="new-feature">{{ feature }}</span>
      <span *ngFor="let plan of planRestrictions" class="plan-badge">{{ plan }}</span>
    </h2>
    <span
      *ngIf="
        !isLoading &&
        onboardingCompleted === OnBoardingStage.Uncompleted &&
        !exludedInOnBoarding
      "
      class="checkmark"
      ><img
        *ngIf="onBoardingStepCompleted"
        src="assets/images/icons/check-small.png"
      />
    </span>
  </div>
  <p [innerHTML]="description"></p>
  <div class="load-icon p-absolute" *ngIf="isLoading">
    <app-loader size="medium"></app-loader>
  </div>
  <div
    class="dropdown p-absolute"
    *ngIf="!isLoading && languageOptions.length > 0"
  >
    <app-dropdown
      placeholder="Select language"
      [options]="getLanguageOptions()"
      [selected]="language.name"
      (selectedChange)="languageChanged($event)"
    ></app-dropdown>
  </div>
  <ng-content *ngIf="hasContent"></ng-content>
  <div class="placeholder d-flex" *ngIf="!hasContent">
    <div>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
