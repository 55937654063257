import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ComponentsModule } from '../components/components.module';
import { PendingChangesGuard } from '../core/guards/pending-changes.guard';
import { SettingsSectionComponent } from './components/settings-section/settings-section.component';
import { AccountDashboardComponent } from './pages/account-settings/account-dashboard/account-dashboard.component';
import { ActivateReturboComponent } from './pages/account-settings/activate-returbo/activate-returbo.component';
import { ContactComponent } from './pages/account-settings/contact/contact.component';
import { ReturnNotificationsComponent } from './pages/account-settings/return-notifications/return-notifications.component';
import { SummaryEmailsComponent } from './pages/account-settings/summary-emails/summary-emails.component';
import { CustomizationDashboardComponent } from './pages/customization/customization-dashboard/customization-dashboard.component';
import { FormCustomizationComponent } from './pages/customization/form-customization/form-customization.component';
import { LanguageComponent } from './pages/customization/language/language.component';
import { EmailContentComponent } from './pages/customization/notifications/email-content/email-content.component';
import { FlowEmailComponent } from './pages/customization/notifications/flow-email/flow-email.component';
import { HideEmailModalComponent } from './pages/customization/notifications/hide-email-modal/hide-email-modal.component';
import { NotificationsComponent } from './pages/customization/notifications/notifications.component';
import { StatusTitleComponent } from './pages/customization/notifications/status-title/status-title.component';
import { UsefulKeywordsModalComponent } from './pages/customization/notifications/useful-keywords-modal/useful-keywords-modal.component';
import { ReturnPolicyComponent } from './pages/customization/return-policy/return-policy.component';
import { AddCarrierPopupComponent } from './pages/integration/shipping/add-carrier-popup/add-carrier-popup.component';
import { AutomaticTrackingComponent } from './pages/integration/shipping/automatic-tracking/automatic-tracking.component';
import { AvailableCarrierServicesComponent } from './pages/integration/shipping/available-carrier-services/available-carrier-services.component';
import { BringComponent } from './pages/integration/shipping/bring/bring.component';
import { CarrierActionsComponent } from './pages/integration/shipping/carrier-actions/carrier-actions.component';
import { CarrierHeaderComponent } from './pages/integration/shipping/carrier-header/carrier-header.component';
import { CredentialsGuideComponent } from './pages/integration/shipping/credentials-guide/credentials-guide.component';
import { DeutschePostComponent } from './pages/integration/shipping/deutsche-post/deutsche-post.component';
import { DhlExpressComponent } from './pages/integration/shipping/dhl-express/dhl-express.component';
import { EnableCountriesComponent } from './pages/integration/shipping/enable-countries/enable-countries.component';
import { EssentialsCarrierLimitPopupComponent } from './pages/integration/shipping/essentials-carrier-limit-popup/essentials-carrier-limit-popup.component';
import { GlsComponent } from './pages/integration/shipping/gls/gls.component';
import { IntegrationShippingDashboardComponent } from './pages/integration/shipping/shipping-dashboard/integration-shipping-dashboard.component';
import { OneCarrierPopupComponent } from './pages/integration/shipping/one-carrier-popup/one-carrier-popup.component';
import { PaperlessTradeComponent } from './pages/integration/shipping/paperless-trade/paperless-trade.component';
import { PostnordComponent } from './pages/integration/shipping/postnord/postnord.component';
import { PricePlanCarriersComponent } from './pages/integration/shipping/price-plan-carriers/price-plan-carriers.component';
import { PricePlanHistoryPopupComponent } from './pages/integration/shipping/price-plan-history-popup/price-plan-history-popup.component';
import { ProformaNotAvailableComponent } from './pages/integration/shipping/proforma-not-available/proforma-not-available.component';
import { RequestQuoteComponent } from './pages/integration/shipping/request-quote/request-quote.component';
import { ReturnAddressComponent } from './pages/integration/shipping/return-address/return-address.component';
import { ShippingCarriesRankingComponent } from './pages/integration/shipping/shipping-carriers-ranking/shipping-carriers-ranking.component';
import { ShippingSlipsComponent } from './pages/integration/shipping/shipping-slips/shipping-slips.component';
import { TrackingErrorPopupComponent } from './pages/integration/shipping/tracking-error-popup/tracking-error-popup.component';
import { UpsComponent } from './pages/integration/shipping/ups/ups.component';
import { ComplaintToggleComponent } from './pages/request-settings/complaints/complaint-toggle/complaint-toggle.component';
import { ComplaintOptionsComponent } from './pages/request-settings/complaints/complaint-options/complaint-options.component';
import { ComplaintImageComponent } from './pages/request-settings/complaints/complaint-image/complaint-image.component';
import { ComplaintImagesInstructionsComponent } from './pages/request-settings/complaints/complaint-image-instructions/complaint-image-instructions.component';
import { ComplaintCommentComponent } from './pages/request-settings/complaints/complaint-comment/complaint-comment.component';
import { ComplaintsDashboardComponent } from './pages/request-settings/complaints/complaints-dashboard/complaints-dashboard.component';
import { EstimatedCostComponent } from './pages/request-settings/complaints/estimated-cost/estimated-cost.component';
import { ExchangesDashboardComponent } from './pages/request-settings/exchanges/exchanges-dashboard/exchanges-dashboard.component';
import { ExchangesComponent } from './pages/request-settings/exchanges/exchanges/exchanges.component';
import { AcceptingReturnRequestsComponent } from './pages/request-settings/general/accepting-return-requests/accepting-return-requests.component';
import { GeneralDashboardComponent } from './pages/request-settings/general/general-dashboard/general-dashboard.component';
import { LocationsComponent } from './pages/request-settings/general/locations/locations.component';
import { OutsourcingComponent } from './pages/request-settings/general/outsourcing/outsourcing.component';
import { PrintedSlipsComponent } from './pages/request-settings/general/printed-slips/printed-slips.component';
import { ReturnDeadlineComponent } from './pages/request-settings/general/return-deadline/return-deadline.component';
import { SpecialDeadlinesComponent } from './pages/request-settings/general/return-deadline/special-deadlines/special-deadlines.component';
import { ReturnReasonsComponent } from './pages/request-settings/general/return-reasons/return-reasons.component';
import { ShippingCostComponent } from './pages/request-settings/general/shipping-cost/shipping-cost.component';
import { RequestSettingsDashboardComponent } from './pages/request-settings/request-settings-dashboard/request-settings-dashboard.component';
import { ReturnOptionsComponent } from './pages/request-settings/returns/return-options/return-options.component';
import { ReturnsDashboardComponent } from './pages/request-settings/returns/returns-dashboard/returns-dashboard.component';
import { SettingsDashboardComponent } from './pages/settings-dashboard/settings-dashboard.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { DhlFreightComponent } from './pages/integration/shipping/dhl-freight/dhl-freight.component';
import { IntegrationSettingsDashboardComponent } from './pages/integration/integration-settings-dashboard/integration-settings-dashboard.component';
import { BundlesComponent } from './pages/integration/other/bundles/bundles.component';
import { IntegrationOtherDashboardComponent } from './pages/integration/other/other-dashboard/integration-other-dashboard.component';
import { SelectPlanSettingsComponent } from './pages/account-settings/select-plan-settings/select-plan-settings.component';
import { ExtendDeadlineComponent } from './pages/request-settings/general/extend-deadline/extend-deadline.component';
import { DeadlineListComponent } from './pages/request-settings/shared-components/deadline-list/deadline-list.component';
import { DeadlineEntryComponent } from './pages/request-settings/shared-components/deadline-list/deadline-entry/deadline-entry.component';

@NgModule({
  declarations: [
    SettingsDashboardComponent,
    RequestSettingsDashboardComponent,
    SettingsSectionComponent,
    LanguageComponent,
    ReturnReasonsComponent,
    DeadlineListComponent,
    DeadlineEntryComponent,
    ReturnDeadlineComponent,
    ExtendDeadlineComponent,
    ContactComponent,
    CustomizationDashboardComponent,
    FormCustomizationComponent,
    ReturnPolicyComponent,
    NotificationsComponent,
    AccountDashboardComponent,
    PostnordComponent,
    LocationsComponent,
    ActivateReturboComponent,
    ShippingCostComponent,
    IntegrationSettingsDashboardComponent,
    IntegrationShippingDashboardComponent,
    SummaryEmailsComponent,
    ReturnNotificationsComponent,
    PrintedSlipsComponent,
    AcceptingReturnRequestsComponent,
    OutsourcingComponent,
    ShippingSlipsComponent,
    CarrierActionsComponent,
    ShippingCarriesRankingComponent,
    CarrierHeaderComponent,
    AvailableCarrierServicesComponent,
    AutomaticTrackingComponent,
    CredentialsGuideComponent,
    TrackingErrorPopupComponent,
    OneCarrierPopupComponent,
    EssentialsCarrierLimitPopupComponent,
    ReturnOptionsComponent,
    ComplaintToggleComponent,
    ComplaintOptionsComponent,
    ComplaintImageComponent,
    ComplaintImagesInstructionsComponent,
    ComplaintCommentComponent,
    EnableCountriesComponent,
    GlsComponent,
    DhlExpressComponent,
    ExchangesComponent,
    RequestQuoteComponent,
    ProformaNotAvailableComponent,
    UsefulKeywordsModalComponent,
    StatusTitleComponent,
    HideEmailModalComponent,
    EmailContentComponent,
    FlowEmailComponent,
    BringComponent,
    PaperlessTradeComponent,
    PricePlanCarriersComponent,
    AddCarrierPopupComponent,
    PricePlanHistoryPopupComponent,
    UpsComponent,
    EstimatedCostComponent,
    GeneralDashboardComponent,
    ExchangesDashboardComponent,
    ComplaintsDashboardComponent,
    ReturnsDashboardComponent,
    DeutschePostComponent,
    DhlFreightComponent,
    ReturnAddressComponent,
    SpecialDeadlinesComponent,
    IntegrationOtherDashboardComponent,
    BundlesComponent,
    SelectPlanSettingsComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    ComponentsModule,
    DragDropModule,
    EditorModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatRippleModule,
  ],
  providers: [PendingChangesGuard],
})
export class SettingsModule {}
