import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProformaNotAvailableComponent } from '../proforma-not-available/proforma-not-available.component';
import { IntegrationAlertService } from '../integration-alert.service';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { FeatureEnum } from 'src/app/shared/enums/feature.enum';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-paperless-trade',
  templateUrl: './paperless-trade.component.html',
  styleUrls: ['./paperless-trade.component.scss'],
})
export class PaperlessTradeComponent implements OnInit {
  @Input() paperlessTrade = false;
  @Input() disabled = false;
  @Input() updateMode = false;
  @Input() carrier = ShippingCarrier.DhlExpress;
  @Input() isReturboGenerated = false;
  @Input() apiOrgNumber = '';
  @Output() selectedValue = new EventEmitter<boolean>(false);
  @Output() orgNumberValue = new EventEmitter<string>();
  
  canActivateProforma = false;
  isLoadingFeatureAccess = true;
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';

  constructor(
    private dialog: MatDialog,
    private featureService: FeatureService,
    private integrationAlertService: IntegrationAlertService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.getProformaFeatureAccess();
  }

  onChange = (event: any) => {
    this.selectedValue.emit(event.value);

    this.integrationAlertService.setAlertSectionForCarrier(
      this.carrier,
      AlertSectionType.PaperlessTrade
    );
  };

  onValueChanged(): void {
    this.orgNumberValue.emit(this.apiOrgNumber);
    this.integrationAlertService.setAlertSectionForCarrier(
      this.carrier,
      AlertSectionType.PaperlessTrade
    );
  }

  onPaperlessTradeClick(event: any) {
    if (!this.canActivateProforma) {
      this.dialog.open(ProformaNotAvailableComponent, {
        width: '477px',
      });
      event.preventDefault();
      return;
    }
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      this.carrier
    );
    return alertType === AlertSectionType.PaperlessTrade;
  }

  getProformaFeatureAccess(): void {
    this.isLoadingFeatureAccess = true;
    this.featureService.getFeatureAccess(FeatureEnum.Proforma).subscribe({
      next: (res) => {
        this.canActivateProforma = res;
        this.isLoadingFeatureAccess = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to check access for Proforma');
        this.isLoadingFeatureAccess = false;
      }
    })
  }
}
