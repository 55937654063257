import { Component } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';

@Component({
  selector: 'app-new-features-modal',
  templateUrl: './new-features-modal.component.html',
  styleUrls: ['./new-features-modal.component.scss'],
})
export class NewFeaturesModalComponent {

  constructor(
    private modalService: ModalService
  ) {}

  date = 'November 2024';

  closeModal(): void {
    this.modalService.dismiss();
  }
}
