import { Component, OnInit } from '@angular/core';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-complaint-comment',
  templateUrl: './complaint-comment.component.html',
  styleUrls: ['./complaint-comment.component.scss'],
})
export class ComplaintCommentComponent implements OnInit {
  title = 'Comment on returned item';
  description =
    'Choose whether you want to make it optional or mandatory for a customer to write a comment about an item they are returning.';
  isLoading = true;
  isSaving = false;

  summaryOptions = [
    'Make it optional for a customer to write a short summary about the item',
    'Make it mandatory for a customer to write a short summary about the item',
    'DON’T allow a customer to write a short summary',
  ];
  boldWords = [
    'mandatory',
    'optional',
    'DON’T allow',
  ];

  complaintSettings: ComplaintSettings = new ComplaintSettings();

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((settings) => {
        if (settings.deadline) {
            this.complaintSettings = settings;
            this.isLoading = false;
        }
    });
  }

  getSelectedSummary(): string {
    switch (this.complaintSettings.summary_option) {
      case PremiumOptions.Optional:
        return this.summaryOptions[0];
      case PremiumOptions.Mandatory:
        return this.summaryOptions[1];
      case PremiumOptions.NotAllowed:
        return this.summaryOptions[2];
    }
  }

  setSelectedSummary(selection: string): void {
    if (selection === this.summaryOptions[0]) {
      this.complaintSettings.summary_option = PremiumOptions.Optional;
    }
    if (selection === this.summaryOptions[1]) {
      this.complaintSettings.summary_option = PremiumOptions.Mandatory;
    }
    if (selection === this.summaryOptions[2]) {
      this.complaintSettings.summary_option = PremiumOptions.NotAllowed;
    }

    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}