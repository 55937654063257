import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ExtendedDeadlineRequest } from "src/app/settings/models/deadline/extended-deadline-request.model";
import { UpdateExtendedDeadline } from "src/app/settings/models/deadline/update-extended-deadline.model";
import { ExtendedDeadline } from "src/app/settings/models/extended-deadline.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class ExtendedDeadlineService {
    
  private extendedDeadlineSubject: BehaviorSubject<ExtendedDeadline[]> = new BehaviorSubject<ExtendedDeadline[]>([]);
  private isLoading = false;
  constructor(private httpClient: HttpClient) {}

  getExtendedDeadlines(): Observable<ExtendedDeadline[]> {
    if (
        this.extendedDeadlineSubject.value.length === 0 &&
        !this.isLoading
      ) {
        this.isLoading = true;
        this.httpClient
          .get<ExtendedDeadline[]>(`${environment.apiUrl}/settings/deadlines/extended`)
          .subscribe((deadlines) => {
            this.isLoading = false;
            this.extendedDeadlineSubject.next(deadlines);
          });
      }
      return this.extendedDeadlineSubject;
  }

  createNewDeadline(deadlineRequest: ExtendedDeadlineRequest): Observable<ExtendedDeadline> {
    return this.httpClient
      .post<ExtendedDeadline>(
        `${environment.apiUrl}/settings/deadlines/extended`,
        deadlineRequest
      );
  }

  deleteDeadline(deadlineId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${environment.apiUrl}/settings/deadlines/extended/${deadlineId}`
    );
  }

  updateDeadlineSubject(deadline: ExtendedDeadline, append: boolean): void {
    const currentDeadlines = this.extendedDeadlineSubject.value;
    let updatedDeadlines = [deadline, ...currentDeadlines];

    if (!append) {
      const index = currentDeadlines.indexOf(deadline);
      currentDeadlines.splice(index, 1);
      updatedDeadlines = [...currentDeadlines];
    }

    this.extendedDeadlineSubject.next(updatedDeadlines);
  }

  archiveDeadline(deadlineId: string): Observable<ExtendedDeadline> {
    const updateRequest = new UpdateExtendedDeadline();
    updateRequest.original_order_id = deadlineId;
    updateRequest.archived = true;

    return this.httpClient
    .put<ExtendedDeadline>(
      `${environment.apiUrl}/settings/deadlines/extended`,
      updateRequest
    );
  }
}