<div class="section">
  <h4 class="section-title">Pro-forma customs document {{ isReturboGenerated? '' : '(paperless)' }} </h4>
  <p class="section section-description">
    The pro-forma functionality grants your business the ability to obtain an
    automatically generated pro-forma document to facilitate customs clearance.
    This feature is exclusively available to those subscribed to the Pro payment
    plan (with no additional cost). If you currently have Starter or
    Essentials plan, then this functionality cannot be used. To access further
    information, please visit the payment plan page.
  </p>

  <div
    *ngIf="showAlert() && updateMode"
    class="mb-12"
  >
    <app-alert
    type="Note"
    [showCloseButton]="true"
    [text]="note"
    imgIcon="../assets/images/icons/alert-triangle.png"
    ></app-alert>
  </div>

  <mat-radio-group
    [ngClass]="{ disabled: disabled || isLoadingFeatureAccess }"
    class="paperlessTrade"
    (change)="onChange($event)"
    [ngModel]="paperlessTrade"
    (click)="onPaperlessTradeClick($event)"
  >
    <mat-radio-button class="paperlessTrade-option" [value]="true"
      >Enable pro-forma document autogeneration for my
      customers</mat-radio-button
    >
    <mat-radio-button class="paperlessTrade-option" [value]="false"
      >Disable this feature</mat-radio-button
    >
  </mat-radio-group>

  <div class="d-flex" *ngIf="isReturboGenerated">
    <div class="w-half">
      <p class="section section-input-label">Org. Nr:</p>
      <input
        type="text"
        name="key"
        class="section-input"
        [(ngModel)]="apiOrgNumber"
        placeholder="e.g. AB1234"
        (change)="onValueChanged()"
        [disabled]="!paperlessTrade"
      />
      <p
        class="validation-message"
        *ngIf="paperlessTrade && !apiOrgNumber"
      >
        *Required for pro-forma
      </p>
    </div>
  </div>

</div>
