import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { combineLatest, first } from 'rxjs';
import { PricePlanFeature } from 'src/app/settings/models/price-plan-feature.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { BillingService } from 'src/app/shared/services/billing.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
})
export class SelectPlanComponent implements OnInit {
  @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadingSettings: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadingPricePlans: EventEmitter<boolean> = new EventEmitter<boolean>();

  pricePlans: PricePlan[] = [];
  currentPricePlanId = '';
  currentPricePlan = '';
  disabled = false;
  showFullContent = false;

  formSettings: ReturnFormSettings = new ReturnFormSettings();

  constructor(
    private billingService: BillingService,
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private formSettingsService: FormSettingsService,
    private router: Router,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const pricePlanSettings = this.pricePlanSettingsService.getSubjectSelectedPricePlan();
    const formSettings = this.formSettingsService.getSubjectFormSettings();

    combineLatest([pricePlanSettings, formSettings]).subscribe({
      next: ([pricePlanSettings, formSettings]) => {
        if (formSettings.secondary_color) {
          this.currentPricePlanId = pricePlanSettings?.id;
          this.currentPricePlan = this.getPricePlanName(
            this.currentPricePlanId
          );
          this.formSettings = formSettings;
          this.loadingSettings.emit(false);
        }
      },
      error: () => {
        this.loadingSettings.emit(false);
        this.errorService.showErrorSnackBar('Failed to load settings.');
      },
    });

    this.billingService.getPricePlans().subscribe({
      next: (res) => {
        this.pricePlans = res;
        this.loadingPricePlans.emit(false);
        this.elementRef.nativeElement.style.setProperty(
          '--number-of-columns',
          res.length
        );
      },
      error: () => {
        this.loadingPricePlans.emit(false);
        this.errorService.showErrorSnackBar('Failed to load price plans.');
      }
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll(`#${event.anchor}`);
        }, 200);
      }
    });
  }

  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  
  getImgSource(icon?: string): string {
    return `../assets/images/icons/${icon}.png` ?? '';
  }

  getPricePlanName(id: string): string {
    const pricePlan = this.pricePlans.find((plan) => plan.id === id);
    return pricePlan?.name ?? '';
  }

  getDisplayPrice(pricePlan: PricePlan): number {
    if (pricePlan.cost_per_month) {
      return pricePlan.cost_per_month;
    }
    return pricePlan.cost_per_extra_return ?? 0;
  }

  getPriceUnit(pricePlan: PricePlan): string {
    return pricePlan.cost_per_month ? '/ month' : '/ return';
  }

  setPricePlan(pricePlan: PricePlan) {
    if (pricePlan.id) {
      if (this.currentPricePlanId !== pricePlan.id && !this.disabled) {
        this.saving.emit(true);
        this.disabled = true;
        this.billingService
          .updatePricePlan(pricePlan.id)
          .pipe(first())
          .subscribe({
            next: (confirmationLink) => {
              if (window.top) {
                window.top.location.href = confirmationLink;
              }
            },
            error: () => {
              this.errorService.showErrorSnackBar('Failed set price plan.');
            },
            complete: () => {
              this.saving.emit(false);
              this.disabled = false;
            },
          });
        this.restoreCustomization(pricePlan.name);
      }
    }
  }

  restoreCustomization(pricePlan: string): void {
    if (pricePlan !== 'Pro') {
      this.formSettings.primary_color = '#DE4066';
      this.formSettings.text_color = '#FFFFFF';
      this.formSettings.secondary_color = '#FFF5F7';
      this.formSettings.show_returbo_emblem = true;
      this.saveFormSettings();
    }
  }

  checkForActivePlans(): boolean {
    return this.currentPricePlanId ? true : false;
  }

  saveFormSettings() {
    this.saving.emit(true);

    this.formSettingsService.saveFormSettings(this.formSettings).subscribe({
      next: () => {
        this.formSettingsService.setFormSettingsSubject(this.formSettings);
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed save settings.');
        this.saving.emit(false);
      },
      complete: () => {
        this.saving.emit(false);
      },
    });
  }
}
